import React, { Component, Fragment } from "react";
import Logo from '../img/logo-new.png';
import whatsappIcon from '../img/whatsappicon.png';
import callButton from '../img/callButton.jpg';
import { Link } from "react-router-dom";
import MobileBookingNav from "../MobileBookingNav";
import Sticky from 'react-sticky-el';

import { IconButton,Button } from "@material-ui/core";


const BookingNav = () => {
    return (
        <>        <header>
        <Sticky>
          <div className="container">
            <div className="row desktop ">
              <div className="col-md-2">
                <div className="header-logo">
                  <Button style={{marginTop:'10%'}} onClick={()=>{window.location.href= "/"}}>
                      <img src={Logo} alt="" />
                    </Button>
                </div>
              </div>
              {
                window.location.pathname === '/book-a-session' &&
                <div className="col-md-10">
                <div className="right-top-bar-bookNav" >
                  <ul style={{display:'flex',justifyContent:'end',alignItems:'center'}}>
                    <li>
                       
                        <IconButton onClick={()=>{
                            window.open('tel:+91 92890 33776')
                        }}>
                        <img src={callButton} style={{width:'25px',height:'25px'}}/>
                        </IconButton>
                       
                      
                    </li>
                    <li>
                        <div style={{width:'1px',height:'20px',backgroundColor:'#0D3C42'}}/>
                    </li>
                    <li>
                        <IconButton onClick={()=>{
                            window.open('https://wa.me/+919289033776')
                        }}>
                            <img src={whatsappIcon} style={{width:'25px',height:'30px'}}/>
                        
                        </IconButton>
                    </li>
                    <li>                  
                      <span style={{fontSize:'14px',fontWeight:'500',color:'#0D3C42'}}>+91 92890 33776</span>

                    </li>

                  </ul>
                </div>
                
              </div>
              }
            
              
            </div>
            
          </div>
        </Sticky>
      </header>
    
      <div className="container">
        {/* <div className="row--"> */}
        <MobileBookingNav />
        {/* </div> */}
      </div>
      </>
    )
}

export default BookingNav