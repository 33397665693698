import React, { useEffect } from "react";

const ZohoForm = () => {
    useEffect(() => {
        try {
            // Create iframe
            const iframe = document.createElement("iframe");
            iframe.src =
                "https://forms.zohopublic.in/horaai/form/Bookasession/formperma/g61aM57SYeWVzmyR9rKk1XT5GseHPJO7TYpdrMeclvY?zf_rszfm=1";
            iframe.style.border = "none";
            iframe.style.height = "1400px";
            iframe.style.width = "99%";
            iframe.style.transition = "all 0.5s ease";
            iframe.setAttribute("aria-label", "Book a session");

            // Append iframe to the div
            const container = document.getElementById(
                "zf_div_g61aM57SYeWVzmyR9rKk1XT5GseHPJO7TYpdrMeclvY"
            );
            container.appendChild(iframe);

            // Add event listener for iframe resizing
            const handleMessage = (event) => {
                const evntData = event.data;
                if (evntData && typeof evntData === "string") {
                    const zf_ifrm_data = evntData.split("|");
                    if (zf_ifrm_data.length === 2 || zf_ifrm_data.length === 3) {
                        const zf_perma = zf_ifrm_data[0];
                        const zf_ifrm_ht_nw =
                            parseInt(zf_ifrm_data[1], 10) + 15 + "px";
                        const iframe =
                            container.getElementsByTagName("iframe")[0];
                        if (
                            iframe.src.indexOf("formperma") > 0 &&
                            iframe.src.indexOf(zf_perma) > 0
                        ) {
                            const prevIframeHeight = iframe.style.height;
                            if (prevIframeHeight !== zf_ifrm_ht_nw) {
                                iframe.style.height = zf_ifrm_ht_nw;
                            }
                        }
                    }
                }
            };

            window.addEventListener("message", handleMessage, false);

            // Cleanup event listener on unmount
            return () => {
                window.removeEventListener("message", handleMessage);
            };
        } catch (e) {
            console.error(e);
        }
    }, []);

    return (
        <div
            id="zf_div_g61aM57SYeWVzmyR9rKk1XT5GseHPJO7TYpdrMeclvY"
            style={{ width: "100%", margin: "0 auto" }}
        ></div>
    );
};

export default ZohoForm;
