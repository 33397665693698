import React, {useEffect} from "react";
    
class UsFCSForm extends React.Component {
    componentDidMount() {
        const script = document.createElement('script');
      script.src = '/js-eu1.hsforms.net/forms/embed/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
          if(window.hbspt) {
              window.hbspt.forms.create({
            region: "eu1",
              portalId: '25237881',
            formId: '71190a75-d480-4c93-b8b6-1f52e8c54bcf',
            target: '#hubspotForm'
          })
        }
      });
    }
    
      render() {
        return (
            <div>
              <div id="hubspotForm"></div>
            </div>
      );
    }
  }
    
    export default UsFCSForm;