import React, { Component, Fragment } from "react";
import { Box,Button,Container,Grid, Typography } from "@material-ui/core";
import internshipLandingBanner from '../img/internship-landing-banner.jpg';
import lawPartnerBanner from '../img/law-partner-banner.png';
import cryPartnerBanner from '../img/cry-partner-banner.png';


export const BaseContainer = (props) => {
    return (
        <Container maxWidth='lg'>
            <Box style={{ marginTop:'10px'}}>
                {props.children}
            </Box>
        </Container>
    );
};

const textStyle = {
    color:'#0D3C42',
    fontWeight:'100',
    fontStyle: 'normal',
    fontFamily:'Cinzel',
    lineHeight:'101%'
}

const InternshipPage = () => {
    return (
        <Fragment>
        <BaseContainer>
            <Box style={{backgroundImage:`url(${internshipLandingBanner})`,backgroundSize: 'cover',padding:'2%',width:'120%',marginLeft:'-10%'}}>
            <Grid container style={{rowGap:'60px',paddingInline:'6%'}}>
            {/* <img src={intershipLandingBanner} style={{position:'absolute',zIndex:'-1',top:'17%',left:'0'}}/> */}
                <Grid xs={12}>
                    <Typography 
                    style={{...textStyle,fontSize:'60px'}}>WELCOME TO THE <br/>ATHENA INTERNSHIP EXPERIENCE</Typography> 
                </Grid>
                <Grid xs={12}>
                    <Typography style={{...textStyle,fontSize:'20px'}}>
                    A curated set of programs designed to provide students with hands-on training, mentorship,<br/> and networking opportunities to help students be <span style={{fontWeight:'600'}}>#BetterThanYesterday</span></Typography> 
                </Grid>
            </Grid>
            </Box>
            <Typography style={{...textStyle,fontSize:'20px',marginTop:'20px',height:'40px',width:'20%',fontFamily:'League Spartan!important',borderBottom:'1px solid #0D3C42'}}>Ongoing opportunities</Typography>
            <Grid container style={{marginTop:'10px'}} >
                <Grid xs={6} >
                    <img src={lawPartnerBanner} style={{width:'90%'}}/> 
                    <Typography style={{...textStyle,fontSize:'14px',position:'absolute',marginTop:'-3%',marginLeft:'13%'}}>Last date: 10th May 2023</Typography>
                    <Button style={{backgroundColor:'#0D3C42',borderRadius:'20px',color:'#fff',marginTop:'10px',width:'20%'}}
                         onClick={()=>{
                            window.location.href = './trilegal';
                        }}
                    >Know more</Button>
                </Grid>
                <Grid xs={6} >
                    <img src={cryPartnerBanner} style={{width:'90%'}}/>
                    <Typography style={{...textStyle,fontSize:'14px',position:'absolute',marginTop:'-3%',marginLeft:'13%'}}>Last date: 10th May 2023</Typography>
                    <Button style={{backgroundColor:'#0D3C42',borderRadius:'20px',color:'#fff',marginTop:'10px',width:'20%'}}
                        onClick={()=>{
                            window.location.href = './cry-internship';
                        }}
                    >Know more</Button>
                </Grid>
            </Grid>
        </BaseContainer>
        </Fragment>
    )
}

export default InternshipPage;