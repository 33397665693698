import React from "react";

class HubspotContactForm2 extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '25237881',
          formId: this.props.formId ?? 'adcb3c4a-645b-414d-981c-6ecbcb590698',
          target: '#hubspotForm',
          onFormSubmitted: (form, data) => {
            console.log("OnFormSubmit is getting called", form, data);

            // Get the form field values directly from the submitted form
            const country = data.submissionValues.country_region___form;
            const state = country === 'India' ? data.submissionValues.state_region___form : '';
            const firstname = data.submissionValues.firstname;
            const lastname = data.submissionValues.lastname;
            const email = data.submissionValues.email;
            const phone = data.submissionValues.phone;

            // Your existing logic for redirection based on country and state
            const satelliteCountries = ['Algeria', 'Bahrain', 'Egypt', 'Israel', 'Jordan', 'Kuwait', 'Libya', 'Morocco', 'Oman', 'Qatar', 'Romania', 'Saudi Arabia', 'Tunisia', 'United Arab Emirates (UAE)', 'Yemen'];
            const satelliteStates = ['Andhra Pradesh', 'Karnataka', 'Kerala', 'Tamil Nadu', 'Telangana', 'Goa', 'Gujarat', 'Dadra and Nagar Haveli and Daman and Diu'];
            
            let redirectUrl;
            
            if(satelliteCountries.includes(country) || (country === 'India' && satelliteStates.includes(state)))
              redirectUrl = 'https://meetings-eu1.hubspot.com/harshvardhan-ballani/satellite-rr-link';
            else if(country === 'India' && state === 'Maharashtra')
              redirectUrl = 'https://meetings-eu1.hubspot.com/harshvardhan-ballani/satellite-west-link';
            else
              redirectUrl = 'https://meetings-eu1.hubspot.com/harshvardhan-ballani/hq-rr-link';

            // Append URL parameters
            redirectUrl += `?firstname=${encodeURIComponent(firstname)}&lastname=${encodeURIComponent(lastname)}&email=${encodeURIComponent(email)}`;

            // Redirect the user
            // TODO: athena website base url
            window.location.href = `https://www.athenaeducation.co.in/thank-you?redirect=${encodeURIComponent(redirectUrl)}`
            // window.location.href = redirectUrl;
          }
        });
      }
    });
  }

  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  }
}

export default HubspotContactForm2;