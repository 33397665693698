import React, { Component, useState } from 'react'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const CountEight = () => {
    const [counterOn, setCounterOn] = useState(false);
    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)}>
            {counterOn && <CountUp start={2000} end={3400} duration={1} delay={0} />}+
        </ScrollTrigger>
    );
};

export default CountEight;