import React, { Fragment,useState } from "react";
import { Box,Button,Container,Grid, Typography,Dialog,DialogTitle } from "@material-ui/core";
import lawBackground from '../img/law-background.jpg';
import PresentByGroup from '../img/presented-by-group.png';
import UsFCSForm from "./UsFCSForm";
import { trilegalDetails,trilegalProgramDetails } from "../utils/constant";
import HubspotContactForm from "./HubspotContactForm";


export const BaseContainer = (props) => {
    return (
        <Container maxWidth='lg'>
            <Box style={{ marginTop:'10px'}}>
                {props.children}
            </Box>
        </Container>
    );
};

const textStyle = {
    color:'#0D3C42',
    fontWeight:'100',
    fontStyle: 'normal',
    fontFamily:'Cinzel',
    lineHeight:'101%'
}


const renderAboutTrilegal = () => {
    return (
        <Typography style={{fontSize:'15px',marginTop:'10px',whiteSpace: "pre-wrap"}}>
            {trilegalDetails}
        </Typography>
    )
}




const UsFCS = () => {
    const [open,setOpen] =useState();
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Fragment>
        <BaseContainer>
            <Box style={{backgroundImage:`url(${lawBackground})`,backgroundSize: 'cover',padding:'2%',width:'120%',marginLeft:'-10%'}}>
            <Grid container style={{rowGap:'30px',paddingInline:'6%'}}>
            {/* <img src={intershipLandingBanner} style={{position:'absolute',zIndex:'-1',top:'17%',left:'0'}}/> */}
                <Grid xs={12}>
                    <span 
                    style={{...textStyle}} className="trilegal-heading" >THE ATHENA LAW IMMERSION PROGRAM</span> 
                </Grid>
                <Grid xs={12}>
                    <Typography style={{...textStyle,fontSize:'20px'}}><span style={{fontWeight:'600'}}>Athena Education</span> in collaboration with <span style={{fontWeight:'600'}}>Trilegal</span></Typography>
                </Grid>
                {/* <Grid xs={12}>
                    <img src={PresentByGroup} style={{width:'350px'}}/>
                </Grid> */}
                <Grid xs={12}>
                    <span style={{...textStyle}} className="trilegal-description">
                    Experience how India’s best legal minds work through a <span style={{fontWeight:'600'}}>3-week workshop</span>.
                    </span>
                </Grid>
                <Grid xs={12}>
                    <Typography style={{...textStyle,fontSize:'20px',backgroundColor:'#FFF',border:'1px solid #0D3C42',padding:'20px',borderRadius:'20px'}}>
                    <span style={{fontWeight:'600'}}>Key Advantages:</span> <br/><br/>• Understand how legislation influences business . 
                    <br/><br />• An official certificate issued by Athena Education and Trilegal. <br/><br/> <span style={{fontWeight:'600'}}>• 10 deserving students</span> will also get the once-in-a-lifetime chance to work with Trilegal’s lawyers.
                    </Typography>
                </Grid>
            </Grid>
            </Box>
            <Grid xs={12} item>
                <Typography style={{...textStyle,fontSize:'20px',fontWeight:'600',marginTop:'20px',height:'40px',width:'200px',fontFamily:'League Spartan!important',borderBottom:'1px solid #0D3C42'}}>About Trilegal</Typography>
            </Grid>
            <Grid xs={12} item>
                {renderAboutTrilegal()}
            </Grid>
            <Grid xs={12} item style={{padding:'20px',paddingBlock:'40px'}}>
                <HubspotContactForm formId={'71190a75-d480-4c93-b8b6-1f52e8c54bcf'}/>
                {/* <UsFCSForm/> */}
            </Grid>
   
        </BaseContainer>
        </Fragment>
    )
}

export default UsFCS