import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import InstaOne from "../img/insta-1.png";
import OX from "../img/ox.jpg";
import acceptances1 from "../img/acceptances/1.jpg";
import acceptances2 from "../img/acceptances/2.jpg";
import acceptances3 from "../img/acceptances/3.jpg";
import acceptances4 from "../img/acceptances/4.jpg";
import acceptances5 from "../img/acceptances/5.jpg";
import acceptances6 from "../img/acceptances/6.jpg";
import acceptances7 from "../img/acceptances/7.jpg";
// import acceptances8 from '../img/acceptances/8.jpg';
// import acceptances9 from '../img/acceptances/9.jpg';
// import acceptances10 from '../img/acceptances/10.jpg';
// import acceptances11 from '../img/acceptances/11.jpg';
// import acceptances12 from '../img/acceptances/12.jpg';
// import acceptances13 from '../img/acceptances/13.jpg';
// import acceptances14 from '../img/acceptances/collegewebbanners-yale.png';

// import acceptances16 from '../img/acceptances/collegewebbanners-Carnegie-Mellon-University.png';
// import acceptances18 from '../img/acceptances/collegewebbanners-JohnsHopkinsUniversity.png';
// import acceptances19 from '../img/acceptances/collegewebbanners-LSE.png';
import { collegeImage } from "../utils/constant";

import Berklee from "../img/college/berklee_2.png";
import Result17 from "../img/results/results-17.jpg";
import ResultLogo17 from "../img/results/results-logo-17.jpg";
import ResultLogo2 from "../img/results/results-logo-2.jpg";
import ResultLogo7 from "../img/results/results-logo-7.jpg";
import Brown from "../img/college/brown.png";
import Dartmouth from "../img/college/dartmouth.png";
import Duke from "../img/college/duke.png";
import Barnard from "../img/college/banard.png";
import LSE from "../img/college/lse.png";
import Georgetown from "../img/college/georgetown.png";
import Williams from "../img/college/williams.png";
import USC from "../img/college/usc.png";
import ucla from "../img/results/results-logo-15.jpg";
import uva from "../img/college/uva.png";
import michigan from "../img/results/results-logo-20.jpg";
import boston from '../img/college/bostonLogo.png'

export default class RecentHome extends Component {
  responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  render() {
    return (
      <div className="recent-home-number-main-mainframe">
        <Carousel responsive={this.responsive} infinite>
          
        <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>02</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={ResultLogo17} alt="princeton" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>05</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={acceptances6} alt="standford" />
            </div>
          </div>
          
          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>09</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={acceptances7} alt="penn" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={ResultLogo2} alt="mit" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>02</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={ResultLogo7} alt="columbia" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>02</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={Brown} alt="brown" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>04</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.cornell} alt="cornell" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>05</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.oxford} alt="oxford" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.cambridge} alt="cambridge" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={Dartmouth} alt="dartMouth" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>08</h2>
            </div>
            <div className="number-recent-home-img">
              <img
                src={collegeImage.impericalLondonCollege}
                alt="impericalLondonCollege"
              />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>06</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.CMU} alt="CMU" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>02</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={Duke} alt="duke" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>05</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.johnsHopkins} alt="johnsHopkins" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>03</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={Barnard} alt="barnard" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>03</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.northwestern} alt="northwest" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>07</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.berkeley} alt="berkeley" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={LSE} alt="londonSchool" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>02</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={Georgetown} alt="Georgetown" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.pomona} alt="pomona" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>03</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={Williams} alt="williams" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>10</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.nyu} alt="nyu" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>15</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={USC} alt="southernCalifornia" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>02</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.amherst} alt="amherst" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>10</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={michigan} alt="michigan" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>11</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={ucla} alt="ucla" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>07</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={uva} alt="uva" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>09</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.georgiaTech} alt="GeorgiaTech" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>11</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.emory} alt="emory" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>18</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.purdue} alt="purdue" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>49</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.illinois} alt="illinois" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>14</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.toronto} alt="toronto" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.vanderbilt} alt="vanderbilt" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>03</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.rice} alt="rice" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>13</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.kings} alt="kings" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>14</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.uncchapel} alt="uncchapel" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>31</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.washington} alt="washington" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>08</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.texas} alt="ut-austin" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>30</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.indiana} alt="indiana" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>18</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.ucsandiego} alt="ucsandiego" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>21</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.northeastern} alt="northeastern" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>19</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.stAndrew} alt="stAndrew" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>09</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.ucl} alt="ucl" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>05</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={boston} alt="boston" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.harvery} alt="harvery" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.risd} alt="risd" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>30</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.kelly} alt="kelly" />
            </div>
          </div>


          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.cmc} alt="cmc" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>01</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={Berklee} alt="berklee" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>16</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.warwick} alt="warwick" />
            </div>
          </div>

          <div className="recent-home-number-main">
            <div className="number-recent-home">
              <h2>18</h2>
            </div>
            <div className="number-recent-home-img">
              <img src={collegeImage.dueham} alt="durham" />
            </div>
          </div>

        </Carousel>
      </div>
    );
  }
}
