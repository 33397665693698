export const schoolList = 
[
    {"label":"CHIREC International School, Hyderabad","city":"Hyderabad","region":"Bangalore",link:'https://google.com'},
    {"label":"Delhi Public School | DPS banglore, Bangalore East","city":"Bangalore","region":"Bangalore"},
    {"label":"Green Hills Academy","city":"Kochi","region":"Bangalore"},
    {"label":"Greenwood High International School, Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"Indus International School, Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"Inventure Academy, Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"Kodaikanal International School","city":"Kodaikanal","region":"Bangalore"},
    {"label":"Mallya Aditi International School","city":"Bangalore","region":"Bangalore"},
    {"label":"Neev Academy, Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"NPS International, Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"NPS International, Chennai","city":"Chennai","region":"Bangalore"},
    {"label":"Oakridge International School, Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"Oakridge International School, Hyderabad","city":"Hyderabad","region":"Bangalore"},
    {"label":"Primus Public School, Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"Stonehill International School, Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"The International School Bangalore","city":"Bangalore","region":"Bangalore"},
    {"label":"Aditya Birla World Academy","city":"Mumbai","region":"Mumbai"},
    {"label":"Ahemdabad International School","city":"Ahmedabad","region":"Mumbai"},
    {"label":"American School of Bombay","city":"Mumbai","region":"Mumbai"},
    {"label":"Arya Vidya Mandir, Bandra West","city":"Mumbai","region":"Mumbai"},
    {"label":"Arya Vidya Mandir, Juhu","city":"Mumbai","region":"Mumbai"},
    {"label":"BD Somani International School, Mumbai","city":"Mumbai","region":"Mumbai"},
    {"label":"Billabong High International School","city":"Mumbai","region":"Mumbai"},
    {"label":"Bombay International School","city":"Mumbai","region":"Mumbai"},
    {"label":"Campion School, Mumbai","city":"Mumbai","region":"Mumbai"},
    
    {"label":"Dhirubhai Ambani International School","city":"Mumbai","region":"Mumbai"},
    {"label":"DSB International School","city":"Mumbai","region":"Mumbai"},
    {"label":"DY Patil International School, Pune","city":"Pune","region":"Mumbai"},
    {"label":"Ecole Mondiale World School","city":"Mumbai","region":"Mumbai"},
    {"label":"Fountainhead School, Surat","city":"Surat","region":"Mumbai"},
    {"label":"Hill Spring International School","city":"Mumbai","region":"Mumbai"},
    {"label":"Hiranandani Foundation School, Powai","city":"Mumbai","region":"Mumbai"},
    {"label":"Hiranandani Foundation School, Thane","city":"Mumbai","region":"Mumbai"},
    {"label":"Indus International School, Pune","city":"Pune","region":"Mumbai"},
    {"label":"Jamnabai Narsee International School","city":"Mumbai","region":"Mumbai"},
    {"label":"Mercedes-Benz International School, Pune","city":"Pune","region":"Mumbai"},
    {"label":"Oberoi International School, JVLR","city":"Mumbai","region":"Mumbai"},
    {"label":"Oberoi International School, OGC","city":"Mumbai","region":"Mumbai"},
    {"label":"Podar International School, Powai","city":"Mumbai","region":"Mumbai"},
    {"label":"Podar International School, Santacruz","city":"Mumbai","region":"Mumbai"},
    {"label":"Singapore International School, Mumbai","city":"Mumbai","region":"Mumbai"},
    {"label":"Symbiosis International School, Pune","city":"Pune","region":"Mumbai"},
    {"label":"The Cathedral and John Connon School","city":"Mumbai","region":"Mumbai"},
    {"label":"American Embassy School","city":"New Delhi","region":"NCR"},
    {"label":"Amity International School, Noida","city":"Noida","region":"NCR"},
    {"label":"Amity International School, Saket","city":"Saket","region":"NCR"},
    {"label":"Bal Bharti Public School, Pitampura","city":"Pitampura","region":"NCR"},
    {"label":"Carmel Convent School, Chandigarh","city":"Chandigarh","region":"NCR"},
    {"label":"City Montessori School, Lucknow","city":"Lucknow","region":"NCR"},
    {"label":"DAV Public School, Gurgaon","city":"Gurugram","region":"NCR"},
    {"label":"Delhi Public School International, Saket | DPS, Saket","city":"New Delhi","region":"NCR"},
    {"label":"Delhi Public School, Ghaziabad | DPS, Ghaziabad","city":"Ghaziabad","region":"NCR"},
    {"label":"Delhi Public School, Gurgaon | DPS, Gurgaon","city":"Gurugram","region":"NCR"},
    {"label":"Delhi Public School, Indirapuram | DPS, Indirapuram","city":"Ghaziabad","region":"NCR"},
    {"label":"Delhi Public School, Mathura Road | DPS, Mathura Road","city":"New Delhi","region":"NCR"},
    {"label":"Delhi Public School, Noida | DPS, Noida","city":"Noida","region":"NCR"},
    {"label":"Delhi Public School, RK Puram | DPS, RK Puram","city":"New Delhi","region":"NCR"},
    {"label":"Delhi Public School, Rohini | DPS, Rohini","city":"New Delhi","region":"NCR"},
    {"label":"GD Goenka Public School, Model Town","city":"New Delhi","region":"NCR"},
    {"label":"GD Goenka Public School, Rohini","city":"New Delhi","region":"NCR"},
    {"label":"GD Goenka Public School, Vasant Kunj","city":"New Delhi","region":"NCR"},
    {"label":"GD Goenka World School, Gurugram","city":"Gurugram","region":"NCR"},
    {"label":"Heritage International Xperiential School","city":"Gurugram","region":"NCR"},
    {"label":"Heritage Xperiential Learning School","city":"Gurugram","region":"NCR"},
    {"label":"Lancers International School, Gurgaon","city":"Gurugram","region":"NCR"},
    {"label":"Lotus Valley International School, Gurgaon","city":"Gurugram","region":"NCR"},
    {"label":"Lotus Valley International School, Noida","city":"Noida","region":"NCR"},
    {"label":"Modern School, Barakhamba Road","city":"New Delhi","region":"NCR"},
    {"label":"Modern School, Vasant Vihar","city":"New Delhi","region":"NCR"},
    {"label":"Pathways School, Noida","city":"Noida","region":"NCR"},
    {"label":"Pathways World School, Aravali","city":"Gurugram","region":"NCR"},
    {"label":"Pathways World School, Gurgaon","city":"Gurugram","region":"NCR"},
    {"label":"Ridge Valley School","city":"Gurugram","region":"NCR"},
    {"label":"Ryan International School, Noida","city":"Noida","region":"NCR"},
    {"label":"Sanskriti School, New Delhi","city":"New Delhi","region":"NCR"},
    {"label":"Scottish High International School, Gurgaon","city":"Gurugram","region":"NCR"},
    {"label":"Shiv Nadar School, Gurugram","city":"Gurugram","region":"NCR"},
    {"label":"Shiv Nadar School, Noida","city":"Noida","region":"NCR"},
    {"label":"Springdales School, Dhaula Kuan","city":"New Delhi","region":"NCR"},
    {"label":"Springdales School, Pusa Road","city":"New Delhi","region":"NCR"},
    {"label":"Step By Step School, Noida","city":"New Delhi","region":"NCR"},
    {"label":"Suncity School, Gurgaon","city":"Gurugram","region":"NCR"},
    {"label":"The British School, New Delhi","city":"New Delhi","region":"NCR"},
    {"label":"The Sanskrit School, Lucknow","city":"Lucknow","region":"NCR"},
    {"label":"The Shri Ram School, Aravali","city":"Gurugram","region":"NCR"},
    {"label":"The Shri Ram School, Moulsari","city":"Gurugram","region":"NCR"},
    {"label":"Vasant Valley School, Delhi","city":"Delhi","region":"NCR"},
    {"label":"American International School, Hong Kong","city":"Hong Kong","region":"RoW"},
    {"label":"American School of Dubai","city":"Dubai","region":"RoW"},
    {"label":"American School, Doha","city":"Doha","region":"RoW"},
    {"label":"Ann Arbor Pioneer High School","city":"Ann Arbor","region":"RoW"},
    {"label":"Bellarmine College Prep, San Jose","city":"San Jose","region":"RoW"},
    {"label":"Delhi Public School, Bhilai | DPS Bhilai","city":"Bhilai","region":"RoW"},
    {"label":"Doha College","city":"Doha","region":"RoW"},
    {"label":"Dubai American Academy","city":"Dubai","region":"RoW"},
    {"label":"Gems Modern Academy","city":"Dubai","region":"RoW"},
    {"label":"Gems World Academy, Dubai","city":"Dubai","region":"RoW"},
    {"label":"Gyananda Academy, Birgunj, Nepal","city":"Birgunj","region":"RoW"},
    {"label":"Jaishree Periwal International School, Jaipur","city":"","region":"RoW"},
    {"label":"Modern High School International","city":"Dubai","region":"RoW"},
    {"label":"Neerja Modi School, Jaipur","city":"Jaipur","region":"RoW"},
    {"label":"NPS International, Guwahati","city":"Guwahati","region":"RoW"},
    {"label":"Palo Alto High School","city":"Palo Alto","region":"RoW"},
    {"label":"Princeton Day School","city":"Princeton","region":"RoW"},
    {"label":"The Doon School, Dehradun","city":"","region":"RoW"},
    {"label":"UWC South East Asia (Dover Campus)","city":"Singapore","region":"RoW"},
    {"label":"UWC South East Asia (East Campus)","city":"Singapore","region":"RoW"},
    {"label":"Woodstock School","city":"","region":"RoW"},
    {"label":"The Mother'S International School","city":"Delhi","region":"NCR"},
    {"label":"Delhi Public School, Dwarka | DPS, Dwarka","city":"Delhi","region":"NCR"},
    {"label":"Ahlcon International School","city":"Delhi","region":"NCR"},
    {"label":"Bluebells International School","city":"Delhi","region":"NCR"},
    {"label":"Delhi Public School,Kolkata | DPS, Kolkata, Ruby Park","city":"Kolkata","region":"RoW"},
    {"label":"The Emerald Heights International School, Indore","city":"Indore","region":"RoW"},
    ]
    
    
export const countryCodeList = [{"country":"Afghanistan","code":"93","iso":"AF"},
{"country":"Albania","code":"355","iso":"AL"},
{"country":"Algeria","code":"213","iso":"DZ"},
{"country":"American Samoa","code":"1-684","iso":"AS"},
{"country":"Andorra","code":"376","iso":"AD"},
{"country":"Angola","code":"244","iso":"AO"},
{"country":"Anguilla","code":"1-264","iso":"AI"},
{"country":"Antarctica","code":"672","iso":"AQ"},
{"country":"Antigua and Barbuda","code":"1-268","iso":"AG"},
{"country":"Argentina","code":"54","iso":"AR"},
{"country":"Armenia","code":"374","iso":"AM"},
{"country":"Aruba","code":"297","iso":"AW"},
{"country":"Australia","code":"61","iso":"AU"},
{"country":"Austria","code":"43","iso":"AT"},
{"country":"Azerbaijan","code":"994","iso":"AZ"},
{"country":"Bahamas","code":"1-242","iso":"BS"},
{"country":"Bahrain","code":"973","iso":"BH"},
{"country":"Bangladesh","code":"880","iso":"BD"},
{"country":"Barbados","code":"1-246","iso":"BB"},
{"country":"Belarus","code":"375","iso":"BY"},
{"country":"Belgium","code":"32","iso":"BE"},
{"country":"Belize","code":"501","iso":"BZ"},
{"country":"Benin","code":"229","iso":"BJ"},
{"country":"Bermuda","code":"1-441","iso":"BM"},
{"country":"Bhutan","code":"975","iso":"BT"},
{"country":"Bolivia","code":"591","iso":"BO"},
{"country":"Bosnia and Herzegovina","code":"387","iso":"BA"},
{"country":"Botswana","code":"267","iso":"BW"},
{"country":"Brazil","code":"55","iso":"BR"},
{"country":"British Indian Ocean Territory","code":"246","iso":"IO"},
{"country":"British Virgin Islands","code":"1-284","iso":"VG"},
{"country":"Brunei","code":"673","iso":"BN"},
{"country":"Bulgaria","code":"359","iso":"BG"},
{"country":"Burkina Faso","code":"226","iso":"BF"},
{"country":"Burundi","code":"257","iso":"BI"},
{"country":"Cambodia","code":"855","iso":"KH"},
{"country":"Cameroon","code":"237","iso":"CM"},
{"country":"Canada","code":"1","iso":"CA"},
{"country":"Cape Verde","code":"238","iso":"CV"},
{"country":"Cayman Islands","code":"1-345","iso":"KY"},
{"country":"Central African Republic","code":"236","iso":"CF"},
{"country":"Chad","code":"235","iso":"TD"},
{"country":"Chile","code":"56","iso":"CL"},
{"country":"China","code":"86","iso":"CN"},
{"country":"Christmas Island","code":"61","iso":"CX"},
{"country":"Cocos Islands","code":"61","iso":"CC"},
{"country":"Colombia","code":"57","iso":"CO"},
{"country":"Comoros","code":"269","iso":"KM"},
{"country":"Cook Islands","code":"682","iso":"CK"},
{"country":"Costa Rica","code":"506","iso":"CR"},
{"country":"Croatia","code":"385","iso":"HR"},
{"country":"Cuba","code":"53","iso":"CU"},
{"country":"Curacao","code":"599","iso":"CW"},
{"country":"Cyprus","code":"357","iso":"CY"},
{"country":"Czech Republic","code":"420","iso":"CZ"},
{"country":"Democratic Republic of the Congo","code":"243","iso":"CD"},
{"country":"Denmark","code":"45","iso":"DK"},
{"country":"Djibouti","code":"253","iso":"DJ"},
{"country":"Dominica","code":"1-767","iso":"DM"},
{"country":"Dominican Republic","code":"1-809, 1-829, 1-849","iso":"DO"},
{"country":"East Timor","code":"670","iso":"TL"},
{"country":"Ecuador","code":"593","iso":"EC"},
{"country":"Egypt","code":"20","iso":"EG"},
{"country":"El Salvador","code":"503","iso":"SV"},
{"country":"Equatorial Guinea","code":"240","iso":"GQ"},
{"country":"Eritrea","code":"291","iso":"ER"},
{"country":"Estonia","code":"372","iso":"EE"},
{"country":"Ethiopia","code":"251","iso":"ET"},
{"country":"Falkland Islands","code":"500","iso":"FK"},
{"country":"Faroe Islands","code":"298","iso":"FO"},
{"country":"Fiji","code":"679","iso":"FJ"},
{"country":"Finland","code":"358","iso":"FI"},
{"country":"France","code":"33","iso":"FR"},
{"country":"French Polynesia","code":"689","iso":"PF"},
{"country":"Gabon","code":"241","iso":"GA"},
{"country":"Gambia","code":"220","iso":"GM"},
{"country":"Georgia","code":"995","iso":"GE"},
{"country":"Germany","code":"49","iso":"DE"},
{"country":"Ghana","code":"233","iso":"GH"},
{"country":"Gibraltar","code":"350","iso":"GI"},
{"country":"Greece","code":"30","iso":"GR"},
{"country":"Greenland","code":"299","iso":"GL"},
{"country":"Grenada","code":"1-473","iso":"GD"},
{"country":"Guam","code":"1-671","iso":"GU"},
{"country":"Guatemala","code":"502","iso":"GT"},
{"country":"Guernsey","code":"44-1481","iso":"GG"},
{"country":"Guinea","code":"224","iso":"GN"},
{"country":"Guinea-Bissau","code":"245","iso":"GW"},
{"country":"Guyana","code":"592","iso":"GY"},
{"country":"Haiti","code":"509","iso":"HT"},
{"country":"Honduras","code":"504","iso":"HN"},
{"country":"Hong Kong","code":"852","iso":"HK"},
{"country":"Hungary","code":"36","iso":"HU"},
{"country":"Iceland","code":"354","iso":"IS"},
{"country":"India","code":"91","iso":"IN"},
{"country":"Indonesia","code":"62","iso":"ID"},
{"country":"Iran","code":"98","iso":"IR"},
{"country":"Iraq","code":"964","iso":"IQ"},
{"country":"Ireland","code":"353","iso":"IE"},
{"country":"Isle of Man","code":"44-1624","iso":"IM"},
{"country":"Israel","code":"972","iso":"IL"},
{"country":"Italy","code":"39","iso":"IT"},
{"country":"Ivory Coast","code":"225","iso":"CI"},
{"country":"Jamaica","code":"1-876","iso":"JM"},
{"country":"Japan","code":"81","iso":"JP"},
{"country":"Jersey","code":"44-1534","iso":"JE"},
{"country":"Jordan","code":"962","iso":"JO"},
{"country":"Kazakhstan","code":"7","iso":"KZ"},
{"country":"Kenya","code":"254","iso":"KE"},
{"country":"Kiribati","code":"686","iso":"KI"},
{"country":"Kosovo","code":"383","iso":"XK"},
{"country":"Kuwait","code":"965","iso":"KW"},
{"country":"Kyrgyzstan","code":"996","iso":"KG"},
{"country":"Laos","code":"856","iso":"LA"},
{"country":"Latvia","code":"371","iso":"LV"},
{"country":"Lebanon","code":"961","iso":"LB"},
{"country":"Lesotho","code":"266","iso":"LS"},
{"country":"Liberia","code":"231","iso":"LR"},
{"country":"Libya","code":"218","iso":"LY"},
{"country":"Liechtenstein","code":"423","iso":"LI"},
{"country":"Lithuania","code":"370","iso":"LT"},
{"country":"Luxembourg","code":"352","iso":"LU"},
{"country":"Macao","code":"853","iso":"MO"},
{"country":"Macedonia","code":"389","iso":"MK"},
{"country":"Madagascar","code":"261","iso":"MG"},
{"country":"Malawi","code":"265","iso":"MW"},
{"country":"Malaysia","code":"60","iso":"MY"},
{"country":"Maldives","code":"960","iso":"MV"},
{"country":"Mali","code":"223","iso":"ML"},
{"country":"Malta","code":"356","iso":"MT"},
{"country":"Marshall Islands","code":"692","iso":"MH"},
{"country":"Mauritania","code":"222","iso":"MR"},
{"country":"Mauritius","code":"230","iso":"MU"},
{"country":"Mayotte","code":"262","iso":"YT"},
{"country":"Mexico","code":"52","iso":"MX"},
{"country":"Micronesia","code":"691","iso":"FM"},
{"country":"Moldova","code":"373","iso":"MD"},
{"country":"Monaco","code":"377","iso":"MC"},
{"country":"Mongolia","code":"976","iso":"MN"},
{"country":"Montenegro","code":"382","iso":"ME"},
{"country":"Montserrat","code":"1-664","iso":"MS"},
{"country":"Morocco","code":"212","iso":"MA"},
{"country":"Mozambique","code":"258","iso":"MZ"},
{"country":"Myanmar","code":"95","iso":"MM"},
{"country":"Namibia","code":"264","iso":"NA"},
{"country":"Nauru","code":"674","iso":"NR"},
{"country":"Nepal","code":"977","iso":"NP"},
{"country":"Netherlands","code":"31","iso":"NL"},
{"country":"Netherlands Antilles","code":"599","iso":"AN"},
{"country":"New Caledonia","code":"687","iso":"NC"},
{"country":"New Zealand","code":"64","iso":"NZ"},
{"country":"Nicaragua","code":"505","iso":"NI"},
{"country":"Niger","code":"227","iso":"NE"},
{"country":"Nigeria","code":"234","iso":"NG"},
{"country":"Niue","code":"683","iso":"NU"},
{"country":"North Korea","code":"850","iso":"KP"},
{"country":"Northern Mariana Islands","code":"1-670","iso":"MP"},
{"country":"Norway","code":"47","iso":"NO"},
{"country":"Oman","code":"968","iso":"OM"},
{"country":"Pakistan","code":"92","iso":"PK"},
{"country":"Palau","code":"680","iso":"PW"},
{"country":"Palestine","code":"970","iso":"PS"},
{"country":"Panama","code":"507","iso":"PA"},
{"country":"Papua New Guinea","code":"675","iso":"PG"},
{"country":"Paraguay","code":"595","iso":"PY"},
{"country":"Peru","code":"51","iso":"PE"},
{"country":"Philippines","code":"63","iso":"PH"},
{"country":"Pitcairn","code":"64","iso":"PN"},
{"country":"Poland","code":"48","iso":"PL"},
{"country":"Portugal","code":"351","iso":"PT"},
{"country":"Puerto Rico","code":"1-787, 1-939","iso":"PR"},
{"country":"Qatar","code":"974","iso":"QA"},
{"country":"Republic of the Congo","code":"242","iso":"CG"},
{"country":"Reunion","code":"262","iso":"RE"},
{"country":"Romania","code":"40","iso":"RO"},
{"country":"Russia","code":"7","iso":"RU"},
{"country":"Rwanda","code":"250","iso":"RW"},
{"country":"Saint Barthelemy","code":"590","iso":"BL"},
{"country":"Saint Helena","code":"290","iso":"SH"},
{"country":"Saint Kitts and Nevis","code":"1-869","iso":"KN"},
{"country":"Saint Lucia","code":"1-758","iso":"LC"},
{"country":"Saint Martin","code":"590","iso":"MF"},
{"country":"Saint Pierre and Miquelon","code":"508","iso":"PM"},
{"country":"Saint Vincent and the Grenadines","code":"1-784","iso":"VC"},
{"country":"Samoa","code":"685","iso":"WS"},
{"country":"San Marino","code":"378","iso":"SM"},
{"country":"Sao Tome and Principe","code":"239","iso":"ST"},
{"country":"Saudi Arabia","code":"966","iso":"SA"},
{"country":"Senegal","code":"221","iso":"SN"},
{"country":"Serbia","code":"381","iso":"RS"},
{"country":"Seychelles","code":"248","iso":"SC"},
{"country":"Sierra Leone","code":"232","iso":"SL"},
{"country":"Singapore","code":"65","iso":"SG"},
{"country":"Sint Maarten","code":"1-721","iso":"SX"},
{"country":"Slovakia","code":"421","iso":"SK"},
{"country":"Slovenia","code":"386","iso":"SI"},
{"country":"Solomon Islands","code":"677","iso":"SB"},
{"country":"Somalia","code":"252","iso":"SO"},
{"country":"South Africa","code":"27","iso":"ZA"},
{"country":"South Korea","code":"82","iso":"KR"},
{"country":"South Sudan","code":"211","iso":"SS"},
{"country":"Spain","code":"34","iso":"ES"},
{"country":"Sri Lanka","code":"94","iso":"LK"},
{"country":"Sudan","code":"249","iso":"SD"},
{"country":"Suriname","code":"597","iso":"SR"},
{"country":"Svalbard and Jan Mayen","code":"47","iso":"SJ"},
{"country":"Swaziland","code":"268","iso":"SZ"},
{"country":"Sweden","code":"46","iso":"SE"},
{"country":"Switzerland","code":"41","iso":"CH"},
{"country":"Syria","code":"963","iso":"SY"},
{"country":"Taiwan","code":"886","iso":"TW"},
{"country":"Tajikistan","code":"992","iso":"TJ"},
{"country":"Tanzania","code":"255","iso":"TZ"},
{"country":"Thailand","code":"66","iso":"TH"},
{"country":"Togo","code":"228","iso":"TG"},
{"country":"Tokelau","code":"690","iso":"TK"},
{"country":"Tonga","code":"676","iso":"TO"},
{"country":"Trinidad and Tobago","code":"1-868","iso":"TT"},
{"country":"Tunisia","code":"216","iso":"TN"},
{"country":"Turkey","code":"90","iso":"TR"},
{"country":"Turkmenistan","code":"993","iso":"TM"},
{"country":"Turks and Caicos Islands","code":"1-649","iso":"TC"},
{"country":"Tuvalu","code":"688","iso":"TV"},
{"country":"U.S. Virgin Islands","code":"1-340","iso":"VI"},
{"country":"Uganda","code":"256","iso":"UG"},
{"country":"Ukraine","code":"380","iso":"UA"},
{"country":"United Arab Emirates","code":"971","iso":"AE"},
{"country":"United Kingdom","code":"44","iso":"GB"},
{"country":"United States","code":"1","iso":"US"},
{"country":"Uruguay","code":"598","iso":"UY"},
{"country":"Uzbekistan","code":"998","iso":"UZ"},
{"country":"Vanuatu","code":"678","iso":"VU"},
{"country":"Vatican","code":"379","iso":"VA"},
{"country":"Venezuela","code":"58","iso":"VE"},
{"country":"Vietnam","code":"84","iso":"VN"},
{"country":"Wallis and Futuna","code":"681","iso":"WF"},
{"country":"Western Sahara","code":"212","iso":"EH"},
{"country":"Yemen","code":"967","iso":"YE"},
{"country":"Zambia","code":"260","iso":"ZM"},
{"country":"Zimbabwe","code":"263","iso":"ZW"}]
    

export const regionList = [
    'Delhi NCR - Haryana - UP - Punjab',
    'Karnataka - Tamil Nadu - Telangana - Andhra Pradesh - Kerala',
    'Maharashtra - Gujarat',
    'Middle East (UAE, Qatar, etc.)',
    'Southeast Asia (Singapore, Malaysia, Indonesia, etc.)',
    'Other'
]

export const referenceList = [
    'Instagram', 
    'Facebook ',
    'Google Search',
    'Athena School Workshop',
    'Athena Event',
    'Referral - Friends & Family',
    'Tutor outside of school',
    'YouTube',
    'LinkedIn',
    'News'
]

export const oneOnOneReferenceList =[
    'Referral - Friends & Family',
]

export const stateList = [
    'Andaman and Nicobar Island',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra and Nagar Haveli and Daman and Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Ladakh',
    'Lakshadweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
    ];
  
  export const countriesName = [
    'India',
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Costa Rica',
    'Côte d`Ivoire',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic (Czechia)',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'DR Congo',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Holy See',
    'Honduras',
    'Hungary',
    'Iceland',
    
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Korea',
    'North Macedonia',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts & Nevis',
    'Saint Lucia',
    'Samoa',
    'San Marino',
    'Sao Tome & Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'St. Vincent & Grenadines',
    'State of Palestine',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe'
  ];
  
export const oneToOneStates =[
        'Andhra Pradesh',
        'Karnataka',
        'Kerala',
        'Tamil Nadu',
        'Telangana',
        'Goa',
        'Gujarat',
        'Maharashtra',
        'Dadra and Nagar Haveli and Daman and Diu'
] 
  
    
export const trilegalDetails = 'Trilegal is a full-service law firm with offices in Bengaluru, Delhi, Gurugram and Mumbai. They have been consistently recognised as the best Indian law firm for the quality of their services and client satisfaction.';
export const trilegalProgramDetails = {
    duration:'3 Weeks',
    dates:'May 15, 2023 - June 2, 2023',
    timeCommitment:'2 hours per week',
    mode:'online',
    skills:'Conflict Management, Leadership, Persuasive Communication, Dispute Settlement, Legal Knowledge',
    syllabus:'During this 3-week workshop, you will gain an understanding of how legislation, business and communities are intertwined and how each influences the other. You will also understand how emerging technologies and work paradigms are effecting change in legislation, and how India’s leading lawyers address these issues.'
}

export const countries = [
    { name: "India", code: "+91", flag: "🇮🇳" },
    { name: "Afghanistan", code: "+93", flag: "🇦🇫" },
    { name: "Albania", code: "+355", flag: "🇦🇱" },
    { name: "Algeria", code: "+213", flag: "🇩🇿" },
    { name: "Andorra", code: "+376", flag: "🇦🇩" },
    { name: "Angola", code: "+244", flag: "🇦🇴" },
    { name: "Antigua and Barbuda", code: "+1-268", flag: "🇦🇬" },
    { name: "Argentina", code: "+54", flag: "🇦🇷" },
    { name: "Armenia", code: "+374", flag: "🇦🇲" },
    { name: "Australia", code: "+61", flag: "🇦🇺" },
    { name: "Austria", code: "+43", flag: "🇦🇹" },
    { name: "Azerbaijan", code: "+994", flag: "🇦🇿" },
    { name: "Bahamas", code: "+1-242", flag: "🇧🇸" },
    { name: "Bahrain", code: "+973", flag: "🇧🇭" },
    { name: "Bangladesh", code: "+880", flag: "🇧🇩" },
    { name: "Barbados", code: "+1-246", flag: "🇧🇧" },
    { name: "Belarus", code: "+375", flag: "🇧🇾" },
    { name: "Belgium", code: "+32", flag: "🇧🇪" },
    { name: "Belize", code: "+501", flag: "🇧🇿" },
    { name: "Benin", code: "+229", flag: "🇧🇯" },
    { name: "Bhutan", code: "+975", flag: "🇧🇹" },
    { name: "Bolivia", code: "+591", flag: "🇧🇴" },
    { name: "Bosnia and Herzegovina", code: "+387", flag: "🇧🇦" },
    { name: "Botswana", code: "+267", flag: "🇧🇼" },
    { name: "Brazil", code: "+55", flag: "🇧🇷" },
    { name: "Brunei", code: "+673", flag: "🇧🇳" },
    { name: "Bulgaria", code: "+359", flag: "🇧🇬" },
    { name: "Burkina Faso", code: "+226", flag: "🇧🇫" },
    { name: "Burundi", code: "+257", flag: "🇧🇮" },
    { name: "Cabo Verde", code: "+238", flag: "🇨🇻" },
    { name: "Cameroon", code: "+237", flag: "🇨🇲" },
    { name: "Canada", code: "+1", flag: "🇨🇦" },
    { name: "Central African Republic", code: "+236", flag: "🇨🇫" },
    { name: "Chad", code: "+235", flag: "🇹🇩" },
    { name: "Chile", code: "+56", flag: "🇨🇱" },
    { name: "China", code: "+86", flag: "🇨🇳" },
    { name: "Colombia", code: "+57", flag: "🇨🇴" },
    { name: "Comoros", code: "+269", flag: "🇰🇲" },
    { name: "Congo, Republic of the", code: "+242", flag: "🇨🇬" },
    { name: "Congo, Democratic Republic of the", code: "+243", flag: "🇨🇩" },
    { name: "Costa Rica", code: "+506", flag: "🇨🇷" },
    { name: "Cote d'Ivoire", code: "+225", flag: "🇨🇮" },
    { name: "Croatia", code: "+385", flag: "🇭🇷" },
    { name: "Cuba", code: "+53", flag: "🇨🇺" },
    { name: "Cyprus", code: "+357", flag: "🇨🇾" },
    { name: "Czech Republic", code: "+420", flag: "🇨🇿" },
    { name: "Denmark", code: "+45", flag: "🇩🇰" },
    { name: "Djibouti", code: "+253", flag: "🇩🇯" },
    { name: "Dominica", code: "+1-767", flag: "🇩🇲" },
    { name: "Dominican Republic", code: "+1-809, +1-829, +1-849", flag: "🇩🇴" },
    { name: "Ecuador", code: "+593", flag: "🇪🇨" },
    { name: "Egypt", code: "+20", flag: "🇪🇬" },
    { name: "El Salvador", code: "+503", flag: "🇸🇻" },
    { name: "Equatorial Guinea", code: "+240", flag: "🇬🇶" },
    { name: "Eritrea", code: "+291", flag: "🇪🇷" },
    { name: "Estonia", code: "+372", flag: "🇪🇪" },
    { name: "Eswatini", code: "+268", flag: "🇸🇿" },
    { name: "Ethiopia", code: "+251", flag: "🇪🇹" },
    { name: "Fiji", code: "+679", flag: "🇫🇯" },
    { name: "Finland", code: "+358", flag: "🇫🇮" },
    { name: "France",code:"+33",flag:"🇫🇷"},
    { name: "Gabon", code: "+241", flag: "🇬🇦" },
    { name: "Gambia", code: "+220", flag: "🇬🇲" },
    { name: "Georgia", code: "+995", flag: "🇬🇪" },
    { name: "Germany", code: "+49", flag: "🇩🇪" },
    { name: "Ghana", code: "+233", flag: "🇬🇭" },
    { name: "Greece", code: "+30", flag: "🇬🇷" },
    { name: "Grenada", code: "+1-473", flag: "🇬🇩" },
    { name: "Guatemala", code: "+502", flag: "🇬🇹" },
    { name: "Guinea", code: "+224", flag: "🇬🇳" },
    { name: "Guinea-Bissau", code: "+245", flag: "🇬🇼" },
    { name: "Guyana", code: "+592", flag: "🇬🇾" },
    { name: "Haiti", code: "+509", flag: "🇭🇹" },
    { name: "Honduras", code: "+504", flag: "🇭🇳" },
    { name: "Hungary", code: "+36", flag: "🇭🇺" },
    { name: "Iceland", code: "+354", flag: "🇮🇸" },
    { name: "Indonesia", code: "+62", flag: "🇮🇩" },
    { name: "Iran", code: "+98", flag: "🇮🇷" },
    { name: "Iraq", code: "+964", flag: "🇮🇶" },
    { name: "Ireland", code: "+353", flag: "🇮🇪" },
    { name: "Israel", code: "+972", flag: "🇮🇱" },
    { name: "Italy", code: "+39", flag: "🇮🇹" },
    { name: "Jamaica", code: "+1-876", flag: "🇯🇲" },
    { name: "Japan", code: "+81", flag: "🇯🇵" },
    { name: "Jordan", code: "+962", flag: "🇯🇴" },
    { name: "Kazakhstan", code: "+7", flag: "🇰🇿" },
    { name: "Kenya", code: "+254", flag: "🇰🇪" },
    { name: "Kiribati", code: "+686", flag: "🇰🇮" },
    { name: "Kosovo", code: "+383", flag: "🇽🇰" },
    { name: "Kuwait", code: "+965", flag: "🇰🇼" },
    { name: "Kyrgyzstan", code: "+996", flag: "🇰🇬" },
    { name: "Latvia", code: "+371", flag: "🇱🇻" },
    { name: "Lebanon", code: "+961", flag: "🇱🇧" },
    { name: "Lesotho", code: "+266", flag: "🇱🇸" },
    { name: "Liberia", code: "+231", flag: "🇱🇷" },
    { name: "Libya", code: "+218", flag: "🇱🇾" },
    { name: "Liechtenstein", code: "+423", flag: "🇱🇮" },
    { name: "Lithuania", code: "+370", flag: "🇱🇹" },
    { name: "Luxembourg", code: "+352", flag: "🇱🇺" },
    { name: "Madagascar", code: "+261", flag: "🇲🇬" },
    { name: "Malawi", code: "+265", flag: "🇲🇼" },
    { name: "Malaysia", code: "+60", flag: "🇲🇾" },
    { name: "Maldives", code: "+960", flag: "🇲🇻" },
    { name: "Mali", code: "+223", flag: "🇲🇱" },
    { name: "Malta", code: "+356", flag: "🇲🇹" },
    { name: "Marshall Islands", code: "+692", flag: "🇲🇭" },
    { name: "Mauritania", code: "+222", flag: "🇲🇷" },
    { name: "Mauritius", code: "+230", flag: "🇲🇺" },
    { name: "Mexico", code: "+52", flag: "🇲🇽" },
    { name: "Micronesia", code: "+691", flag: "🇫🇲" },
    { name: "Moldova", code: "+373", flag: "🇲🇩" },
    { name: "Monaco", code: "+377", flag: "🇲🇨" },
    { name: "Mongolia", code: "+976", flag: "🇲🇳" },
    { name: "Montenegro", code: "+382", flag: "🇲🇪" },
    { name: "Morocco", code: "+212", flag: "🇲🇦" },
    { name: "Mozambique", code: "+258", flag: "🇲🇿" },
    { name: "Myanmar", code: "+95", flag: "🇲🇲" },
    { name: "Namibia", code: "+264", flag: "🇳🇦" },
    { name: "Nauru", code: "+674", flag: "🇳🇷" },
    { name: "Nepal", code: "+977", flag: "🇳🇵" },
    { name: "Netherlands", code: "+31", flag: "🇳🇱" },
    { name: "New Zealand", code: "+64", flag: "🇳🇿" },
    { name: "Nicaragua", code: "+505", flag: "🇳🇮" },
    { name: "Niger", code: "+227", flag: "🇳🇪" },
    { name: "Nigeria", code: "+234", flag: "🇳🇬" },
    { name: "North Korea", code: "+850", flag: "🇰🇵" },
    { name: "North Macedonia", code: "+389", flag: "🇲🇰" },
    { name: "Norway", code: "+47", flag: "🇳🇴" },
    { name: "Oman", code: "+968", flag: "🇴🇲" },
    { name: "Pakistan", code: "+92", flag: "🇵🇰" },
    { name: "Palau", code: "+680", flag: "🇵🇼" },
    { name: "Palestine", code: "+970", flag: "🇵🇸" },
    { name: "Panama", code: "+507", flag: "🇵🇦" },
    { name: "Papua New Guinea", code: "+675", flag: "🇵🇬" },
    { name: "Paraguay", code: "+595", flag: "🇵🇾" },
    { name: "Peru", code: "+51", flag: "🇵🇪" },
    { name: "Philippines", code: "+63", flag: "🇵🇭" },
    { name: "Poland", code: "+48", flag: "🇵🇱" },
    { name: "Portugal", code: "+351", flag: "🇵🇹" },
    { name: "Qatar", code: "+974", flag: "🇶🇦" },
    { name: "Romania", code: "+40", flag: "🇷🇴" },
    { name: "Russia", code: "+7", flag: "🇷🇺" },
    { name: "Rwanda", code: "+250", flag: "🇷🇼" },
    { name: "Saint Kitts and Nevis", code: "+1-869", flag: "🇰🇳" },
    { name: "Saint Lucia", code: "+1-758", flag: "🇱🇨" },
    { name: "Saint Vincent and the Grenadines", code: "+1-784", flag: "🇻🇨" },
    { name: "Samoa", code: "+685", flag: "🇼🇸" },
    { name: "San Marino", code: "+378", flag: "🇸🇲" },
    { name: "Sao Tome and Principe", code: "+239", flag: "🇸🇹" },
    { name: "Saudi Arabia", code: "+966", flag: "🇸🇦" },
    { name: "Senegal", code: "+221", flag: "🇸🇳" },
    { name: "Serbia", code: "+381", flag: "🇷🇸" },
    { name: "Seychelles", code: "+248", flag: "🇸🇨" },
    { name: "Sierra Leone", code: "+232", flag: "🇸🇱" },
    { name: "Singapore", code: "+65", flag: "🇸🇬" },
    { name: "Slovakia", code: "+421", flag: "🇸🇰" },
    { name: "Slovenia", code: "+386", flag: "🇸🇮" },
    { name: "Solomon Islands", code: "+677", flag: "🇸🇧" },
    { name: "Somalia", code: "+252", flag: "🇸🇴" },
    { name: "South Africa", code: "+27", flag: "🇿🇦" },
    { name: "South Korea", code: "+82", flag: "🇰🇷" },
    { name: "South Sudan", code: "+211", flag: "🇸🇸" },
    { name: "Spain", code: "+34", flag: "🇪🇸" },
    { name: "Sri Lanka", code: "+94", flag: "🇱🇰" },
    { name: "Sudan", code: "+249", flag: "🇸🇩" },
    { name: "Suriname", code: "+597", flag: "🇸🇷" },
    { name: "Sweden", code: "+46", flag: "🇸🇪" },
    { name: "Switzerland", code: "+41", flag: "🇨🇭" },
    { name: "Syria", code: "+963", flag: "🇸🇾" },
    { name: "Taiwan", code: "+886", flag: "🇹🇼" },
    { name: "Tajikistan", code: "+992", flag: "🇹🇯" },
    { name: "Tanzania", code: "+255", flag: "🇹🇿" },
    { name: "Thailand", code: "+66", flag: "🇹🇭" },
    { name: "Timor-Leste", code: "+670", flag: "🇹🇱" },
    { name: "Togo", code: "+228", flag: "🇹🇬" },
    { name: "Tonga", code: "+676", flag: "🇹🇴" },
    { name: "Trinidad and Tobago", code: "+1-868", flag: "🇹🇹" },
    { name: "Tunisia", code: "+216", flag: "🇹🇳" },
    { name: "Turkey", code: "+90", flag: "🇹🇷" },
    { name: "Turkmenistan", code: "+993", flag: "🇹🇲" },
    { name: "Tuvalu", code: "+688", flag: "🇹🇻" },
    { name: "Uganda", code: "+256", flag: "🇺🇬" },
    { name: "Ukraine", code: "+380", flag: "🇺🇦" },
    { name: "United Arab Emirates", code: "+971", flag: "🇦🇪" },
    { name: "United Kingdom", code:"+44", flag: "🇬🇧" },
    { name: "United States of America", code: "+1", flag: "🇺🇸" },
    { name: "Uruguay", code: "+598", flag: "🇺🇾" },
    { name: "Uzbekistan", code: "+998", flag: "🇺🇿" },
    { name: "Vanuatu", code: "+678", flag: "🇻🇺" },
    { name: "Vatican City (Holy See)", code: "+379", flag: "🇻🇦" },
    { name: "Venezuela", code: "+58", flag: "🇻🇪" },
    { name: "Vietnam", code: "+84", flag: "🇻🇳" },
    { name: "Yemen", code: "+967", flag: "🇾🇪" },
    { name: "Zambia", code: "+260", flag: "🇿🇲" },
    { name: "Zimbabwe", code: "+263", flag: "🇿🇼" },
];

export const oneOnOneSchoolList = [
    'American Embassy School',
    'Amity International School Noida',
    'Amity International School Saket',
    'Calcutta International School',
    'City Montessori School Lucknow',
    'Chirec International School',
    'Delhi Public School, Noida | DPS, Noida',
    'Delhi Public School, Mathura Road | DPS, Mathura Road',
    'Delhi Public School, RK Puram | DPS, RK Puram',
    'Delhi Public School, Rohini | DPS, Rohini',
    'DPS Edge International School Gurgaon',
    'DPS Gurgaon',
    'DPS International School Saket',
    'G.D Goenka School, Vasant Kunj',
    'Genesis Global School',
    'Heritage School',
    'Heritage Xperiential Learning School Gurgaon',
    'Jayshree Periwal International School',
    'Kids Club',
    'KR Mangalam School',
    'Kunskapsskolan',
    'La Martiniere',
    'Lancers International School',
    'Lotus Valley International School',
    'Lotus Valley International School Noida',
    'Mayo College',
    'Modern High School International',
    'Modern School, Barakhamba Road',
    'Modern School, Vasant Vihar',
    'Modern High School for Girls',
    'Meridian School',
    'Neerja Modi School',
    'Pathways Noida',
    'Pathways School Gurgaon',
    'Pathways World School Aravali',
    'Sanskriti School',
    'Schottish High International School',
    'Seth M.R Jaipuria School',
    'Shikshantar School',
    'Shiv Nadar School, Noida',
    'Shiv Nadar School, Noida',
    'Step by Step Noida',
    'Strawberry Fields High School',
    'Suncity School',
    "St. Xavier's Collegiate School",
    'The British School',
    'The Doon School',
    'The Heritage School',
    'The Lawrence School Sanawar',
    'The Shri Ram School Aravali',
    'The Shri Ram School Moulsari',
    'The Shriram Millennium School',
    'Vasant Valley School',
    "Welham Girls' School",
    'Woodstock School'
];

export const collegeImage = {
    babson:'https://hora-website.s3.ap-south-1.amazonaws.com/Babson.png',
    dueham:'https://hora-website.s3.ap-south-1.amazonaws.com/Durham.png',
    emory:'https://hora-website.s3.ap-south-1.amazonaws.com/Emory.png',
    impericalLondonCollege:'https://hora-website.s3.ap-south-1.amazonaws.com/Imperial+college+london.png',
    kelly:'https://hora-website.s3.ap-south-1.amazonaws.com/Kelley.png',
    kings:"https://hora-website.s3.ap-south-1.amazonaws.com/King_s+college.png",
    stAndrew:"https://hora-website.s3.ap-south-1.amazonaws.com/St+Andrews.png",
    ucl:"https://hora-website.s3.ap-south-1.amazonaws.com/UCL.png",
    texas:"https://hora-website.s3.ap-south-1.amazonaws.com/UT+Austin.png",
    warwick:"https://hora-website.s3.ap-south-1.amazonaws.com/Warwick.png",
    berklee:'https://hora-website.s3.ap-south-1.amazonaws.com/Berklee.png',
    cornell:'https://hora-website.s3.ap-south-1.amazonaws.com/Cornell.png',
    johnsHopkins:'https://hora-website.s3.ap-south-1.amazonaws.com/johns+hopkins.png',
    northwestern:'https://hora-website.s3.ap-south-1.amazonaws.com/Northwestern.png',
    nyu:'https://hora-website.s3.ap-south-1.amazonaws.com/NYU.png',
    oxford:'https://hora-website.s3.ap-south-1.amazonaws.com/Oxford.png',
    parsons:'https://hora-website.s3.ap-south-1.amazonaws.com/Parsons.png',
    pomona:'https://hora-website.s3.ap-south-1.amazonaws.com/Pomona.png',
    rice:'https://hora-website.s3.ap-south-1.amazonaws.com/RICE.png',
    risd:'https://hora-website.s3.ap-south-1.amazonaws.com/RISD.png',
    tufts:'https://hora-website.s3.ap-south-1.amazonaws.com/Tufts.png',
    cambridge: 'https://hora-website.s3.ap-south-1.amazonaws.com/Cambridge.png',
    CMU: 'https://hora-website.s3.ap-south-1.amazonaws.com/CMU.png',
    georgiaTech: 'https://hora-website.s3.ap-south-1.amazonaws.com/GeorgiaTech.png',
    uMichigan: 'https://hora-website.s3.ap-south-1.amazonaws.com/UMichigan.png',
    cmc: 'https://hora-website.s3.ap-south-1.amazonaws.com/cmc.png',
    harvery: 'https://hora-website.s3.ap-south-1.amazonaws.com/harvery+mudd.png',
    boston: 'https://hora-website.s3.ap-south-1.amazonaws.com/boston.png',
    northeastern: 'https://hora-website.s3.ap-south-1.amazonaws.com/northeastern.png',
    ucsandiego: 'https://hora-website.s3.ap-south-1.amazonaws.com/uc+san+diego.png',
    indiana: 'https://hora-website.s3.ap-south-1.amazonaws.com/indiana+university.png',
    washington: 'https://hora-website.s3.ap-south-1.amazonaws.com/washington.png',
    uncchapel: 'https://hora-website.s3.ap-south-1.amazonaws.com/unc+chapel+hill.png',
    vanderbilt: 'https://hora-website.s3.ap-south-1.amazonaws.com/vanderbilt.png',
    toronto: 'https://hora-website.s3.ap-south-1.amazonaws.com/UToronto.png',
    illinois: 'https://hora-website.s3.ap-south-1.amazonaws.com/UIUC.png',
    purdue: 'https://hora-website.s3.ap-south-1.amazonaws.com/Purdue.png',
    amherst: 'https://hora-website.s3.ap-south-1.amazonaws.com/amherst.png',
    berkeley: 'https://hora-website.s3.ap-south-1.amazonaws.com/Berkeley.png',


    


    campusBarnard:'https://hora-website.s3.ap-south-1.amazonaws.com/barnard.png',
    campusBerklee:'https://hora-website.s3.ap-south-1.amazonaws.com/berkelee+college+of+musoc.png',
    campusBrown:'https://hora-website.s3.ap-south-1.amazonaws.com/brown.png',
    campusPrinceton:'https://hora-website.s3.ap-south-1.amazonaws.com/brown-1.png',
    campusCaltech:'https://hora-website.s3.ap-south-1.amazonaws.com/caltech.png',
    campusCmu:'https://hora-website.s3.ap-south-1.amazonaws.com/cmu.png',
    campusColumbia:'https://hora-website.s3.ap-south-1.amazonaws.com/columbia+(1).png',
    campusDartMouth:'https://hora-website.s3.ap-south-1.amazonaws.com/dartmouth.png',
    campusDuke:'https://hora-website.s3.ap-south-1.amazonaws.com/duke+(1).png',
    campusGeogia:'https://hora-website.s3.ap-south-1.amazonaws.com/georgia+tech.png',
    campusHarvard:'https://hora-website.s3.ap-south-1.amazonaws.com/harvard.png',
    campusKing:'https://hora-website.s3.ap-south-1.amazonaws.com/kings+college.png',
    campusLse:'https://hora-website.s3.ap-south-1.amazonaws.com/lse.png',
    campusUiuc:'https://hora-website.s3.ap-south-1.amazonaws.com/lse-1.png',
    campusMIT:'https://hora-website.s3.ap-south-1.amazonaws.com/mit.png',
    campusNorthWest:'https://hora-website.s3.ap-south-1.amazonaws.com/northwestern+(1).png',
    campusNyu:'https://hora-website.s3.ap-south-1.amazonaws.com/nyu.png',
    campusParsons:'https://hora-website.s3.ap-south-1.amazonaws.com/parsons.png',
    campusPomona:'https://hora-website.s3.ap-south-1.amazonaws.com/pomona+(1).png',
    campusPurdue:'https://hora-website.s3.ap-south-1.amazonaws.com/purdue+(1).png',
    campusRisd:'https://hora-website.s3.ap-south-1.amazonaws.com/risd+(1).png',
    campusStandford:'https://hora-website.s3.ap-south-1.amazonaws.com/stanford.png',
    campusToronto:'https://hora-website.s3.ap-south-1.amazonaws.com/toronto.png',
    campusUcSanDiego:'https://hora-website.s3.ap-south-1.amazonaws.com/uc+san+diego.png',
    campusUCL:'https://hora-website.s3.ap-south-1.amazonaws.com/ucl+(1).png',
    campusUcs:'https://hora-website.s3.ap-south-1.amazonaws.com/ucs.png ',
    campusWilliams:'https://hora-website.s3.ap-south-1.amazonaws.com/williams+college.png',
}