import React, { Component } from "react";
import Event from './img/event.png';
import Map from './img/map.png';
import Clock from './img/clock.png';
import Cal from './img/cla.png';
import MapWhite from './img/map-white.png';
import ClockWhite from './img/clock-white.png';
import CalWhite from './img/ClaWhite.png';
import ArrowOne from './img/arrow-1.png';
import Arrowtwo from './img/arrow-2.png';
import MediaOne from './img/media-1.jpg';
import MediaTwo from './img/media-2.jpg';
import MediaThree from './img/media-3.jpg';
import MediaFour from './img/media-4.jpg';
import MediaFive from './img/media-5.jpg';
import MediaSix from './img/media-6.jpg';
import MediaSeven from './img/media-7.jpg';
import MediaEight from './img/media-8.jpg';
import MediaNine from './img/media-9.jpg';
import MediaTen from './img/media-10.jpg';
import EconomicTimes from './img/press/the economic times.png'
import FinancialExpress from './img/press/the financial express.png'
import Inc42 from './img/press/inc 42.png'
import TheHindu from './img/press/the hindu.png'
import TimesNow from './img/press/times now.png'
import YourStory from './img/press/your story.png'
import BusinessStandard from './img/press/business standard.png'
import TOI from './img/press/toi.png'
import NDTV from './img/press/ndtv_logo.png'
import IndiaToday from './img/press/india today.png'
import { Link } from "react-router-dom";


class HomeEvent extends React.Component {

    render() {
        return (
            <div>
                <div className="home-event-section-page">
                    <div className="home-event-section-page-left">
                        <div className="home-page-para-heading">
                            <h3>A peak into our world</h3>
                            <h2>Join our upcoming events</h2>
                        </div>
                        <div className="event-left-section">
                            <div className="event-left-section-left">
                                <img src={Event} />
                            </div>
                            <div className="event-left-section-right">

                                <h4>Name of the event</h4>
                                <ul className="event-home-top-list">
                                    <li> <img className="normal" src={Cal} /><img className="white" src={CalWhite} /> <span>00-00-0000</span></li>
                                    <li> <img className="normal" src={Clock} /> <img className="white" src={ClockWhite} /> <span>6:00 pm</span></li>
                                </ul>
                                <ul className="event-home-top-list">
                                    <li> <img className="normal" src={Map} /> <img className="white" src={MapWhite} /> <span>Athena Campus, Gurugram</span></li>

                                </ul>
                            </div>
                        </div>
                        <div className="event-left-section">
                            <div className="event-left-section-left">
                                <img src={Event} />
                            </div>
                            <div className="event-left-section-right">

                                <h4>Name of the event</h4>
                                <ul className="event-home-top-list">
                                    <li> <img className="normal" src={Cal} /><img className="white" src={CalWhite} /> <span>00-00-0000</span></li>
                                    <li> <img className="normal" src={Clock} /> <img className="white" src={ClockWhite} /> <span>6:00 pm</span></li>
                                </ul>
                                <ul className="event-home-top-list">
                                    <li> <img className="normal" src={Map} /> <img className="white" src={MapWhite} /> <span>Athena Campus, Gurugram</span></li>

                                </ul>
                            </div>
                        </div>
                        <div className='track-your-college-left-link'>
                            <Link className='footer-top-map-img-button-link' to='/resources'>Learn more <span><img src={ArrowOne} className="normal" alt="" /><img src={Arrowtwo} className="normal-ho" alt="" /></span></Link>
                        </div>
                    </div>
                    <div className="home-event-section-page-right">
                        <div className="home-page-para-heading align-center">
                            <h3>Press coverage</h3>
                            <h2>Athena in the media</h2>
                        </div>
                        <div className="home-page-logo-media-frame">
                            <ul>
                                <li><img src={EconomicTimes} onClick={()=>window.open('https://economictimes.indiatimes.com/nri/study/ivy-league-whisperers-why-high-end-education-consultants-are-in-huge-demand-from-delhi-to-dhanbad/articleshow/108167836.cms?from=mdr')}/></li>
                                <li><img src={FinancialExpress} onClick={()=>window.open('https://www.financialexpress.com/jobs-career/education-athena-educations-flagship-mentorship-programme-and-growth-revenue-from-operations-grew-97-in-fy22-3279228/')}/></li>
                                <li><img src={Inc42} onClick={()=>window.open('https://inc42.com/startups/how-athenas-education-three-pillars-of-training-prepare-ivy-league-aspirants-as-overseas-admission-gets-tougher/')}/></li>
                                <li><img src={TheHindu} onClick={()=>window.open('https://www.thehindu.com/education/why-research-should-be-a-part-of-high-school-curriculum/article68040573.ece')}/></li>
                                <li><img src={TimesNow} onClick={()=>window.open('https://www.timesnownews.com/education/start-early-princeton-graduate-founder-athena-education-poshak-agrawal-on-admissions-to-top-universities-abroad-article-106318215')}/></li>
                                <li><img src={YourStory} onClick={()=>window.open('https://yourstory.com/2022/06/student-mentoring-startup-athena-education-consultation-admission')}/></li>
                                <li><img src={BusinessStandard} onClick={()=>window.open('https://www.business-standard.com/content/specials/life-coach-rahul-subramaniam-decodes-how-student-led-businesses-create-a-new-way-for-india-122032100793_1.html')}/></li>
                                <li><img src={TOI} onClick={()=>window.open('https://timesofindia.indiatimes.com/blogs/voices/redefining-liberal-arts-student-to-entrepreneur/')}/></li>
                                <li><img src={NDTV} onClick={()=>window.open('https://www.ndtv.com/education/on-increased-female-enrollment-in-stem-courses-a-thumbs-up-from-experts-4973808 ')}/></li>
                                <li><img src={IndiaToday} onClick={()=>window.open('https://www.indiatoday.in/india-today-insight/story/why-indian-students-are-making-a-beeline-for-us-colleges-2463769-2023-11-16')}/></li>

                            </ul>


                        </div>
                        <div className='track-your-college-left-link align-center'>
                            <Link className='footer-top-map-img-button-link' to='/in-the-news'>Read more <span><img src={ArrowOne} className="normal" alt="" /><img src={Arrowtwo} className="normal-ho" alt="" /></span></Link>
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}

export default HomeEvent;