import React, { Component, Fragment } from "react";
import { Box,Button,Container,Grid, Typography } from "@material-ui/core";
import internshipLandingBanner from '../img/internship-landing-banner.jpg';
import lawBackground from '../img/law-background.jpg';
import cryPartnerBanner from '../img/cry-partner-banner.png';
import PresentByGroup from '../img/presented-by-group.png';

export const BaseContainer = (props) => {
    return (
        <Container maxWidth='lg'>
            <Box style={{ marginTop:'10px'}}>
                {props.children}
            </Box>
        </Container>
    );
};

const textStyle = {
    color:'#0D3C42',
    fontWeight:'100',
    fontStyle: 'normal',
    fontFamily:'Cinzel',
    lineHeight:'101%'
}

const programDetail = "Lorem ipsum dolor sit amet consectetur. Orci aenean placerat consequat ornare varius vitae. Suspendisse habitasse posuere ut eget porta ultricies.\nQuis faucibus pellentesque laoreet interdum suscipit amet urna. Consectetur consectetur non elementum eget pharetra ac. Massa sit sagittis diam scelerisque. Vel eu sed congue viverra eget pulvinar viverra amet.\nQuis faucibus pellentesque laoreet interdum suscipit amet urna. Consectetur consectetur non elementum eget pharetra"

const renderProgramDetails = () => {
    return (
        <Typography style={{fontSize:'14px',marginTop:'10px',whiteSpace: "pre-wrap"}}>
            {programDetail}
        </Typography>
    )
}

const renderTable = ()=>{
    const data=[
        {phase:'workshop',description:'Orientation session for student',owner:['Athena','Trilegal'],date:'May 10th 2023'},
        {phase:'workshop',description:'Orientation session for student',owner:['Athena','Trilegal'],date:'May 10th 2023'},
        {phase:'workshop',description:'Orientation session for student',owner:['Athena','Trilegal'],date:'May 10th 2023'},
        {phase:'workshop',description:'Orientation session for student',owner:['Athena','Trilegal'],date:'May 10th 2023'},
        {phase:'workshop',description:'Orientation session for student',owner:['Athena','Trilegal'],date:'May 10th 2023'},
        {phase:'workshop',description:'Orientation session for student',owner:['Athena','Trilegal'],date:'May 10th 2023'},
        {phase:'workshop',description:'Orientation session for student',owner:['Athena','Trilegal'],date:'May 10th 2023'},
        {phase:'workshop',description:'Orientation session for student',owner:['Athena','Trilegal'],date:'May 10th 2023'}
    
    ]
    return (
        <table style={{marginTop:'20px'}}>
        <tr >
          <th style={{width:'30%',textAlign:'center'}}>Phase</th>
          <th style={{width:'30%',textAlign:'center'}}>Description</th>
          <th style={{width:'30%',textAlign:'center'}}>Owner</th>
          <th style={{width:'30%',textAlign:'center'}}>Date</th>
        </tr>
        {
            data.map(item=>(
                <tr style={{height:'40px',textAlign:'center'}}>
                    <td>{item.phase}</td>
                    <td>{item.description}</td>
                    <td>
                        {item.owner?.map((val,index)=>(
                            <span>{index!==0 ? ',':''}{val}</span>
                        ))}
                    </td>
                    <td style={{padding:'10px'}}>{item.date}</td>
                </tr>
            ))
        }
      </table>
      )
}

const CryInternship = () => {
    return (
        <Fragment>
        <BaseContainer>
            <Box style={{backgroundImage:`url(${lawBackground})`,backgroundSize: 'cover',padding:'2%',width:'120%',marginLeft:'-10%'}}>
            <Grid container style={{rowGap:'60px',paddingInline:'6%'}}>
            {/* <img src={intershipLandingBanner} style={{position:'absolute',zIndex:'-1',top:'17%',left:'0'}}/> */}
                <Grid xs={12}>
                    <Typography 
                    style={{...textStyle,fontSize:'60px'}}>THE ATHENA LAW IMMERSION PROGRAM</Typography> 
                </Grid>
                <Grid xs={12}>
                    <img src={PresentByGroup} style={{width:'45%'}}/>
                </Grid>
                <Grid xs={12}>
                    <Typography style={{...textStyle,fontSize:'20px'}}>
                        Step into the minds of India's foremost legal advocates with this <span style={{fontWeight:'600'}}>3-week workshop</span> series.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography style={{...textStyle,fontSize:'20px',backgroundColor:'#FFF',border:'1px solid #0D3C42',padding:'20px',borderRadius:'20px'}}>
                    <span style={{fontWeight:'600'}}>Key Advantages:</span> <br/><br/>• Gain a birds-eye view of how legislation functions in India. 
                    <br/><br />• An official certificate upon completion. <br/><br/> <span style={{fontWeight:'600'}}>• 10 deserving students</span> will also get the once-in-a-lifetime chance to gain field experience with Trilegal as interns.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                <Button style={{backgroundColor:'#0D3C42',borderRadius:'20px',color:'#fff',marginTop:'10px',width:'40%',height:'40px',textTransform:'none',fontSize:'14px'}}
                    >Sign up today</Button>
                </Grid>

            </Grid>
            </Box>
            <Typography style={{...textStyle,fontSize:'20px',fontWeight:'600',marginTop:'20px',height:'40px',width:'15%',fontFamily:'League Spartan!important',borderBottom:'1px solid #0D3C42'}}>Program Details</Typography>
            <Grid xs={12}>
            {renderProgramDetails()}
            {renderTable()}
            <Button style={{backgroundColor:'#0D3C42',borderRadius:'20px',color:'#fff',marginTop:'10px',width:'40%',height:'40px',textTransform:'none',fontSize:'14px'}}
                    >Sign up today</Button>
            </Grid>
            <Grid xs={12}>
            <Box  style={{height:'100px'}}/>
            </Grid>
            
            
        </BaseContainer>
        </Fragment>
    )
}

export default CryInternship