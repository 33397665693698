import React, { Component } from "react";
import { Link } from "react-router-dom";

import whatsappIcon from './img/whatsappicon.png';
import callButton from './img/callButton.jpg';
import Logo from './img/logo-new.png';
import 'react-accessible-accordion/dist/fancy-example.css';

import { IconButton,Button } from "@material-ui/core";



class MobileBookingNav extends React.Component {
  constructor() {
    super();

    this.state = {
      displayMenu: false,
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  render() {
    return (
      <div className="mobile-menu-header">
        <div className="container--">
          <div className="col-md-2--">
            <div className="header-logo-mobile">
                <Button>
                <img src={Logo} alt="" />
                </Button>
                
             
            </div>
          </div>
          <div className="col-md-10--">
          </div>
        </div>

      </div>

    );
  }
}

export default MobileBookingNav;