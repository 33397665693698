import React, { Component } from "react";
import { render } from 'react-dom';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@carbon/react';
import "../container/testimonials.css";

import StudentTestimonials from '../img/Student-Testimonials-1.jpg';
import StudentTestimonials2 from '../img/Student-Testimonials-2.jpg';
import StudentTestimonials3 from '../img/Student-Testimonials-3.jpg';
import StudentTestimonials4 from '../img/Student-Testimonials-4.jpg';
import StudentTestimonials5 from '../img/Student-Testimonials-5.jpg';
import StudentTestimonials6 from '../img/Student-Testimonials-6.jpg';
import StudentTestimonials7 from '../img/Student-Testimonials-7.jpg';

import Sukriti from '../img/testimonial/student/Sukriti Ojha 2023 Icon.png'
import Eesha from '../img/testimonial/student/Eesha Garimella 83_83.png'
import Taarush from '../img/testimonial/student/Taarush Grover 83_83.png'
import Agastyaa from '../img/testimonial/student/Agastyaa Vishwanath 83_83.png'
import Isha from '../img/testimonial/student/Isha Jain 83_83.png'
import Aryan from '../img/testimonial/student/Aryan Agarwal 83_83.png'
import Saumya from '../img/testimonial/student/Saumya Chaudhry 83_83.png'
import Avihan from '../img/testimonial/student/Avihan Jain 83_83.png'
import Anmol from '../img/testimonial/student/Anmol Jain 83_83.png'
import Viraj from '../img/testimonial/student/Viraj Malik 83_83.png'
import Mehar from '../img/testimonial/student/Mehar Bhasin 83_83.png'

import SukritiLarge from '../img/testimonial/student/sukriti ojha 249_305.png'
import EeshaLarge from '../img/testimonial/student/eesha garimella 249_305.png'
import TaarushLarge from '../img/testimonial/student/taarush grover 249_305.png'
import AgastyaaLarge from '../img/testimonial/student/agastya vishwanath 249_305.png'
import IshaLarge from '../img/testimonial/student/isha jain 249_305.png'
import AryanLarge from '../img/testimonial/student/aryan agarwal 249_305.png'
import SaumyaLarge from '../img/testimonial/student/Saumya Chaudhry 249_305.png'
import AvihanLarge from '../img/testimonial/student/avihan jain 249_305.png'
import AnmolLarge from '../img/testimonial/student/anmol jain 249_305.png'
import VirajLarge from '../img/testimonial/student/viraj malik 249_305.png'
import MeharLarge from '../img/testimonial/student/mehar bhasin 249_305.png'

export default class STUDENTTESTIMONIALS extends Component {

    render() {

        return (
            <div className="test-bor-img-cy" style={{ width: '100%' }}>
                <Tabs>
                    <div className="container">
                        <TabList aria-label="List of tabs">
                            <Tab>
                                <img src={StudentTestimonials} />
                            </Tab>
                            <Tab>
                                <img src={StudentTestimonials2} />
                            </Tab>
                            <Tab>
                                <img src={StudentTestimonials3} />
                            </Tab>
                            <Tab>
                                <img src={StudentTestimonials4} />
                            </Tab>
                            <Tab>
                                <img src={StudentTestimonials5} />
                            </Tab>
                            <Tab>
                                <img src={StudentTestimonials6} />
                            </Tab>
                            <Tab>
                                <img src={StudentTestimonials7} />
                            </Tab>
                            <Tab>
                                <img src={Sukriti} />
                            </Tab>
                            <Tab>
                                <img src={Eesha} />
                            </Tab>
                            <Tab>
                                <img src={Taarush} />
                            </Tab>
                            <Tab>
                                <img src={Agastyaa} />
                            </Tab>
                            <Tab>
                                <img src={Isha} />
                            </Tab>
                            <Tab>
                                <img src={Aryan} />
                            </Tab>
                            <Tab>
                                <img src={Saumya} />
                            </Tab>
                            <Tab>
                                <img src={Avihan} />
                            </Tab>
                            <Tab>
                                <img src={Anmol} />
                            </Tab>
                            <Tab>
                                <img src={Viraj} />
                            </Tab>
                            <Tab>
                                <img src={Mehar} />
                            </Tab>
                        </TabList>
                    </div>
                    <TabPanels>
                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={StudentTestimonials} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Daksh Chokra</h4>
                                                <h5>University of Pennsylvania, '20 | Athena '16</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>I've definitely grown with Athena to evolve into a new, more confident 2.0 version of myself. The team focuses on amplifying and building our unique brand without diluting our unique voice. Rahul sir's various productivity hacks and obsession with striving for 100% is what I truly admire.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={StudentTestimonials2} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Moksha Sharma</h4>
                                                <h5>University of Chicago, '21 | Athena '17</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>I wasn't aware of my interest in Politics and English - though I knew I was good at them, but didn't know whether I could pursue them in my career. Athena helped me find my passions for these subjects and consequently helped me get into a college that was the right fit for my interests and passions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={StudentTestimonials3} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Prithvi Oak</h4>
                                                <h5>Brown University '25 | Athena '21</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>
                                                    They ensured that all parts of my application were looked into with meticulous detail. The team ensured that I was geared and ready with the best application.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={StudentTestimonials4} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Peehu Agarwal</h4>
                                                <h5>Stanford University, '26 | Athena '22</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>
                                                    Going into the application process, one thing I severely underestimated was the number of moving pieces there were - the essays, activity list, the LORS, etc. Working with Athena, I was able to figure out a game plan to help me achieve my dream of getting into Stanford.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={StudentTestimonials5} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Karan Kashyap</h4>
                                                <h5>Brown University, ‘25 | Athena, ‘21</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>The most important life skill that I learned at Athena was self-reflection. Rahul sir helped me realize early on why I like the things I do, what motivates me and how to translate that into effective action. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={StudentTestimonials6} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Naman Mansukhani</h4>
                                                <h5>Carnegie Mellon University, ‘25</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>One hurdle that Athena helped me cross was the essay writing process. They systematically broke down how to write an essay. They held weekly seminars on writing an essay. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={StudentTestimonials7} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Nehal Sindhu</h4>
                                                <h5>University of California, Berkeley, ‘25 | Athena, ‘21</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>Athena helped me get out of my comfort zone. Athena stands for being there for and standing with you during the worst and strangest of times.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={SukritiLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Sukriti Ojha</h4>
                                                <h5>Yale University ‘27 | Athena ‘23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>Athena made me understand—at almost every step—how my projects aligned with the values I stand for and the vision I had for myself. I took the call on every aspect of my application, but their advice and support were indispensable to any stride I could take. They sculpted my heightened imagination into concrete steps that could put forth my true “brand” and eventually make my application seem impressive yet genuine and personal.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={EeshaLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Eesha Garimella</h4>
                                                <h5>UC Berkeley ‘27 | Athena ‘23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>One of the projects that I did with Athena was breast cancer surveys and screening camps for rural Indian women in Karnataka. This was a multi-step project, and it pushed me to my limits. When I joined Athena, I had different things that I was interested in and different existing extracurriculars, but there was no common thread. Athena gave me direction, bound them all together, and gave me a solid path forward.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={TaarushLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Taarush Grover</h4>
                                                <h5>Stanford University, ‘28 | Athena ‘24</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>Athena understood my fit. I wanted to be in a place with an entrepreneurial environment that fosters creativity and my interdisciplinary nature while also having a great academic community. During my essay ideation process, they captured my multifaceted nature; each essay targeted a specific aspect of my personality. Athena made me think about my authentic self. They honed that individuality in my essays.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={AgastyaaLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Agastyaa Vishwanath</h4>
                                                <h5>Imperial ‘27 | Athena ‘23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>It had been my dream to write a research paper, but I thought I'd have to wait until my third or fourth year in college. I came to Athena and found out that they have a full-fledged department for research. Athena will adapt to exactly what you want! They are a bank of wisdom, coming in with your problem, and they're there to solve it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={IshaLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Isha Jain</h4>
                                                <h5>Stanford '27 | Athena '23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>They don't really try to fit you in a box and I think that's what works when you know you're applying to colleges as well because they want to see you for your most authentic self.The support and personalized attention from Athena made a significant difference in my journey. I felt guided and supported in discovering and showcasing my unique strengths and interests, which ultimately helped me stand out in my college applications.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={AryanLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Aryan Agarwal</h4>
                                                <h5>Yale'27 | Athena'23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>Athena helped me really understand what I'm passionate about and also what I would like to engage with in the next four years of my life and beyond college. They gave a common theme to my profile, the extracurriculars that would not only stand out on my resume,  but also play into my passions. So while deciding on my research projects, the kind of startup I wanted to do, or even kind of subjects I want to take at school,  Athena really helped me create that perfect blend that allowed me to think that yeah, this is going to look really good on my resume and on my application.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={SaumyaLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Saumya Chaudhry</h4>
                                                <h5>Barnard '27 | Athena '23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>Athena helped me discover my true self.  Initially, I was scared but excited about finding a supportive community to grow with. Athena's sessions, meetings, and writing clubs were pivotal, culminating in the joyful moment of college acceptance. They guided me away from following my brother's footsteps and helped me realize my individuality, leading me to a college that perfectly fits me. Despite being set on a design school, Athena broadened my perspective to consider liberal arts. They helped me see the significance in my life experiences, transforming seemingly insignificant details into compelling essay content. Athena's approach felt like a walk through a meadow, encouraging collaboration, boldness, and creativity.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={AvihanLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Avihan Jain</h4>
                                                <h5>Duke '27 | Athena '23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>Athena has been instrumental in helping me stand on my own feet. The Saturday Academy workshops were pivotal the teachings and presentations helped me build confidence and develop essential skills. The essays and deep conversations with Chandra Ma’am led me to introspect, uncovering my strengths and weaknesses, and fostering personal growth. Participating in the First Robotics Competition with Athena, where I led the team and became a Dean's List member, was a significant challenge. It taught me true leadership, especially while managing a diverse team. Athena's guidance on profile building was not about ticking boxes but about finding and pursuing my passions. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={AnmolLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Anmol Jain</h4>
                                                <h5>UPenn '27 | Athena '23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>The most challenging extracurricular for me was the research work, as I wrote three research papers during high school. Conducting primary research, interviews, and surveys, especially with limited exposure to academic writing in a school, pushed me the most and helped me grow significantly. Writing numerous essays and using Athena’s AOS templates allowed me to reflect deeply on my experiences and relationships, helping me understand myself better. Getting into UPenn during the early decision round was a significant moment, and Athena's encouragement to complete my regular decision essays early alleviated a lot of stress. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={VirajLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Viraj Malik</h4>
                                                <h5>CMU '27 | Athena '23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>Building a strong profile for college applications takes time, and Athena helped me refine my projects and discover my passions, ultimately translating them onto my college application. Athena kept me motivated and on track, the application process, although overwhelming at times, became manageable. Athena's program allowed me to explore fields like physics and economics, leading me to renewable technology. The Athena Research Program, particularly challenging with its intensive sessions and new learnings, helped me publish a paper, which was a significant achievement.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="testimonials-all-page-align">
                                <div className="container">
                                    <div className="testimonials-all-page-align-middle">
                                        <div className="testimonials-all-page-align-middle-left">
                                            <img src={MeharLarge} />
                                        </div>
                                        <div className="testimonials-all-page-align-middle-right">
                                            <div className="testimonials-all-page-align-middle-right-middle">
                                                <h4>Mehar Bhasin</h4>
                                                <h5>Dartmouth '27 | Athena '23</h5>
                                                <div className="bordertestimonials-all-page-align-middle-right"></div>
                                                <p>Throughout the college process, several factors kept me going, including the unwavering support of my parents and the entire team at Athena. There were many times when I felt confused and uncertain, but everyone at Athena were always there to guide me. Writing college essays helped me gain clarity on what I genuinely enjoy and want to pursue. This self-discovery process, although challenging, was incredibly valuable.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        
                    </TabPanels>
                </Tabs>
            </div>
        )
    }
}