import {Fragment} from "react";

const Culture = () => {
    return (
        <Fragment>
            <h1 className="culture-header">
                THE TENETS OF ATHENA
            </h1>
            <div className="culture-container">

                <h2 className="culture-title">
                    WHAT WE ARE LIKE
                </h2>
                <div className="culture-description">
                    <p className='para'>
                        We’re a work in progress. As a company and as people, we don’t claim to be perfect. But we do
                        and
                        always will aspire towards ideals. We’ll keep evolving and getting #BetterThanYesterday and so
                        will
                        this document!
                    </p>
                    <p className='para'>
                        We aim to be a powerful force for good. We find our purpose in making lasting, large-scale
                        contributions to society.
                    </p>
                    <p className='para'>
                        This compels us to disregard boundaries and synthesize what others may try to balance or simply
                        dismiss as opposites: art and science, capital and impact, truth and compassion, theory and
                        practice, Eastern and Western perspectives, ambition and humility.
                    </p>
                    <p className='para'>
                        With every challenge we face and overcome, we improve our capability and resolve to make a
                        positive
                        impact.
                    </p>
                    <p className='para'>
                        This document outlines the ideals we believe in and what we appreciate in the people we work
                        with.
                    </p>
                    <p className='para'>
                        If you’re seeking to work and grow with us, we encourage you to assess how closely you can align
                        yourself with our culture. We look for people who can understand and adapt to things that are
                        great,
                        and work with us to improve things that aren’t.
                    </p>
                </div>


                <h2 className="culture-title">
                    IDEALS WE BELIEVE IN
                </h2>
                <div className="culture-description">
                    <div className="culture-alt-title">
                        <div className="bar"/>
                        <div>
                            <h4 style={{margin: 0, lineHeight: 1.5}}>
                                Excellence & Harmony
                            </h4>
                            <p style={{margin: 0}}>
                                Both go together. We do not compromise one for the other. Both go together.
                            </p>
                        </div>
                    </div>
                    <p className='para'>
                        We do not compromise one for the other.
                        We model ourselves after an orchestra. We believe the work we do is like a great composition. We
                        bring together people who are individually extraordinary at their craft and highly effective in
                        producing a symphony when working together.
                    </p>
                    <p className='para'>
                        Every great ensemble needs great players on every instrument. Our team is an intellectually
                        diverse
                        ensemble of people who bring their own skills, aspirations, and priorities.
                    </p>
                    <p className='para'>
                        That said, we know what distinguishes improvisation from amateur play is great craftsmanship – a
                        deep understanding of the craft and command over one’s tools. We hold ourselves and everyone to
                        high
                        standards of craftsmanship and performance.
                    </p>
                    <p className='para'>
                        Collectively, we hold incredible respect for individual time, effort, and talent. For example,
                        deadlines for us are sacrosanct. We treat each deadline like a public performance at a global
                        orchestra. Missing deadlines as individuals means we slow down and hold up the entire team.
                    </p>
                    <p className='para'>
                        We’re building an identifiably humane organisation - a team of individuals who find their
                        success in
                        the success of the company and hence care deeply about it. When such people need us, we also go
                        out
                        of our way to make exceptions for them.
                    </p>
                    <div className="culture-alt-title" style={{marginTop: '6rem'}}>
                        <div className="bar"></div>
                        <h4 style={{margin: 0, lineHeight: 1.5}}>
                            Growth
                        </h4>
                    </div>
                    <p className='para'>
                        We’re young and understand that skills and early efforts compound over time. We also consider
                        work
                        as the most incredible instrument of self-development.
                    </p>
                    <p className='para'>
                        Hence, we’re hungry to do more and to do better. We wish to accomplish multiples of what a
                        typical
                        large organisation would in any given time. Think about staging a performance at an elite stage
                        in a
                        month, while another orchestra would take a year.
                    </p>
                    <p className='para'>
                        And we believe in being absolutely ready before we put on a public performance. This manifests
                        for
                        us and our team as accelerated growth and personal development.
                    </p>
                    <p className='para'>
                        We are conscious of the trade-offs here - we work hard, and sometimes we practise our craft
                        beyond
                        the designated rehearsal hours.
                    </p>
                    <p className='para'>
                        That said, we disproportionately reward disproportionate performance. And we provide
                        disproportionate opportunities to disproportionate potential.
                    </p>
                    <div className="culture-alt-title" style={{marginTop: '6rem'}}>
                        <div className="bar"></div>
                        <h4 style={{margin: 0, lineHeight: 1.5}}>
                            Thinking Long Term
                        </h4>
                    </div>
                    <p className='para'>
                        We think long-term. That means discipline and effort today in the service of a successful
                        future. We
                        understand that thinking short-term means getting swayed by trends or relying on luck.
                    </p>
                    <p className='para'>
                        We’re also an optimistic bunch. We understand that people who think tomorrow is going to be
                        worse
                        than today would not delay gratification, which means they do not put in their best efforts in
                        the
                        present.
                    </p>
                    <p className='para'>
                        We love the process of discovery and making mistakes in rehearsal. We use our judgement to
                        balance
                        speed and perfection. This means we start early, work through limited information, and ambiguity
                        to
                        consistently converge on clarity over time.
                    </p>
                </div>

                <h2 className="culture-title">
                    WHAT WE LOVE IN THE PEOPLE WE WORK WITH
                </h2>
                <div className="culture-description">
                    <p className='para'>
                        In any human enterprise, some people end up performing better and growing further than others.
                        Over
                        time, every culture is able to reflect back and articulate what has made some people thrive.
                    </p>
                    <p className='para'>
                        This section outlines what we love in people we work with.
                    </p>
                    <div className="culture-alt-title">
                        <div className="bar"></div>
                        <h4 style={{margin: 0, lineHeight: 1.5}}>
                            Drive toward Mastery
                        </h4>
                    </div>
                    <ul>
                        <li>
                            <p className="para">
                                We love people who understand their part in the ensemble, are committed to mastering
                                their
                                craft
                                with discipline, dedication, and extreme ownership for themselves. This translates into
                                a
                                deep-rooted ambition to be exceptional at what they do.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love a combination of capability, commitment, and creativity. We demand a combination
                                of
                                capability and commitment. If you’re consistently short on one of the 2, you will not go
                                very
                                far. For people who have all 3, we’re patient even if there’s a temporary dip in
                                performance.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who understand that the stakes are high. We understand that there are
                                always
                                constraints, and the right thing to do may not always be clear. We love people who do
                                their
                                personal best no matter what and who use their judgement to do the right thing every
                                time.
                            </p>
                        </li>
                    </ul>
                    <div className="culture-alt-title" style={{marginTop: '6rem'}}>
                        <div className="bar"></div>
                        <h4 style={{margin: 0, lineHeight: 1.5}}>
                            A winning mindset
                        </h4>
                    </div>
                    <ul>
                        <li>
                            <p className="para">
                                We love people who are excited about their work and display a “bias for action.” We
                                understand
                                that genuine excitement manifests as a combination of joy and a desire to do.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who carry a winning mindset. This means getting better with adversity,
                                rehearsing
                                today for tomorrow’s performance. It’s the sole transferable skill that permeates
                                disciplines,
                                fields of work, and defines long-term individual success. It’s a decisive distinguishing
                                factor
                                in who goes further.
                            </p>
                        </li>
                    </ul>
                    <div className="culture-alt-title" style={{marginTop: '6rem'}}>
                        <div className="bar"></div>
                        <h4 style={{margin: 0, lineHeight: 1.5}}>
                            Humility & Intellectual Curiosity
                        </h4>
                    </div>
                    <ul>
                        <li>
                            <p className="para">
                                We love a combination of curiosity and humility. If you can learn continuously even from
                                people
                                who you may not like, with zero entitlement, there’s no stopping you. We love people who
                                have a
                                relentless pursuit of growth and absorb every feedback in stride.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who associate with, contribute to, and learn from other high performers,
                                and
                                don’t feel threatened by them. We love people who can learn fast and effectively apply
                                those
                                learnings.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who think deeply, who critique prevailing assumptions and suggest better
                                approaches. We’re not fans of people challenging existing practices with limited or
                                partial
                                understanding and without suggestions for improvement.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who go out of their way to help colleagues from other teams – who look at
                                it
                                as
                                an opportunity to learn something, who understand that building long-term reciprocal
                                relationships is essential to success.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who can balance idealism and pragmatism. We understand that our pursuit
                                of
                                ideals
                                needs to be undertaken in the real world and that progress is more important than
                                perfection.
                            </p>
                        </li>
                    </ul>
                    <div className="culture-alt-title" style={{marginTop: '6rem'}}>
                        <div className="bar"></div>
                        <h4 style={{margin: 0, lineHeight: 1.5}}>
                            Conviction
                        </h4>
                    </div>
                    <ul>
                        <li>
                            <p className="para">
                                We like people who speak their minds respectfully.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who demonstrate high levels of conviction toward the decisions made. It
                                should
                                not matter, once the arguments are made, if the decision favors their point of view or
                                not.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love independent thinkers - people who dare to make up their own mind and not be
                                swayed
                                by
                                group think.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who combine the courage and elegance of giving their peers feedback
                                directly,
                                who
                                can read a room and adapt their communication style so they can work effectively with
                                different
                                people. We strongly discourage politics or involving people who aren’t relevant. Such
                                people
                                surface themselves over time and don’t go very far.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love people who are courageous. We understand that life is unpredictable, that
                                everyone
                                struggles to cope at times and that everyone is afraid of something. We love people who
                                take
                                action despite their fears.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We know everyone makes mistakes. We love people who have the grit to acknowledge and fix
                                their
                                mistakes. We as a firm are equally committed to acknowledging and fixing our mistakes.
                                We
                                dislike willing negligence.
                            </p>
                        </li>
                    </ul>
                </div>

                <h2 className="culture-title">
                    WHAT LEADERSHIP MEANS TO US
                </h2>
                <div className="culture-description">
                    <p className='para'>
                        In every culture or social setting, a few people step up naturally. They do so not just by
                        exceptional performance, but also through investment in others. We believe in leaders who are
                        not
                        anointed, but in those who emerge as obvious choices due to their contributions
                    </p>
                    <ul>
                        <li>
                            <p className="para">
                                Leaders sincerely invest time and effort in coaching and mentoring others.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                Leaders act first on behalf of the entire company, then for their team, and at last for
                                themselves. This means pushing their teams without being protective, working on the
                                team’s
                                and their own areas of growth, and recognizing talent outside their teams.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                Extreme ownership is a prerequisite to leadership. Leaders never say “that’s not my
                                job.” It
                                means being resourceful, enterprising, and exuding a drive for getting things done and
                                investing in others.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                A great crafts person can identify the right problems and define them correctly. A
                                leader
                                must marshal resources and people beyond their own craft to solve problems.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                Leaders demonstrate affiliation with the company in their behavior. They independently
                                undertake corrective interventions when a discussion isn’t productive, getting the group
                                back on track and moving forward positively and productively.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                We love open interdepartmental discussions and interdisciplinary inputs. We defer to the
                                person with the best ideas in the room, not necessarily the designated expert on the
                                topic.
                                Leadership means having great ideas, communicating them well, and being able to execute
                                them
                                cohesively. It also means recognizing great ideas wherever they come from, and
                                appreciating
                                the unique intellectual gifts and lived experiences of all stakeholders.
                            </p>
                        </li>
                        <li>
                            <p className="para">
                                Leaders demonstrate the ability to function independently and exercise judgment to ask
                                for
                                help when they struggle.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default Culture;
