import React, { useEffect } from 'react';

const CalendlyPage = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    
      return (
        <div className="calendly-inline-widget" data-url="https://calendly.com/athena-admissions-team/delhi-ncr?hide_gdpr_banner=1" style={{ minWidth: '320px', height: '700px' }} />
      );
}

export default CalendlyPage;