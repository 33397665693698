import React, { Fragment } from "react";
import "react-multi-carousel/lib/styles.css";
import "react-ig-feed/dist/index.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const SliderInsta = (props) => {
  const instaPosts = props.instaPosts;

  return (
    <Fragment>
      <Swiper
        navigation
        loop
        modules={[Navigation, Autoplay]}
        slidesPerView={
          window.innerWidth >= 1280
            ? 6
            : window.innerWidth >= 1024
            ? 4
            : window.innerWidth >= 768
            ? 3
            : 1
        }
        spaceBetween={
          window.innerWidth >= 1024 ? 2 : window.innerWidth >= 768 ? 4 : 0
        }
        autoplay={{
          delay: 2000,
        }}
      >
        {instaPosts?.map((post) => (
          <SwiperSlide key={post?.id}>
            <div
              onClick={() => window.open(post?.permalink, "_blank")}
              style={{ height: "33rem", textAlign: 'center' }}
            >
              <img
                src={
                  post["thumbnail_url"] ? post?.thumbnail_url : post?.media_url
                }
                style={{ objectFit: "cover", height: "inherit" }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Fragment>
  );
};

export default SliderInsta;
