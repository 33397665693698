import React, { Component, Fragment } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Testi from '../img/testi.png';
import TestiTwo from '../img/testi-m.png';
import ParentTestimonials1 from '../img/Parent-Testimonials-1.jpg';
import ParentTestimonials2 from '../img/Parent-Testimonials-2.jpg';
import ParentTestimonials3 from '../img/Parent-Testimonials-3.jpg';
import ParentTestimonials4 from '../img/Parent-Testimonials-4.jpg';
import ParentTestimonials5 from '../img/Parent-Testimonials-5.png';
import PT6 from '../img/testimonial/parent/pooja malik 83_83.png'
import PT7 from '../img/testimonial/parent/mr bhasin 83_83.png'
import PT8 from '../img/testimonial/parent/parents of abhishek mukherjee 83_83.png'

const ParentsTestimonials = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const array = [
        { description: 'When we connected with Athena, we learned that Athena doesn\'t catch the fish for the student; it teaches the student fishing. I have seen Tulip transform before my own eyes and I wish we had signed up with Athena in the 9th grade. The earlier you sign up, the more value you get! We were three years late.', image: ParentTestimonials1, name: 'Preeti Kaul', subheading: 'Parent of Ish Kaul | Princeton University, \'22 | Athena \'18' },
        { description: 'I appreciate the meticulous and thorough expertise Athena provided with the undergrad application process, the personalized approach to understanding my child\'s unique strengths and opportunities. Then, the journey of nudging each child to become more self-aware, hone in on their colleges of choice, allowing the child to discover the best fits for themself both in terms of academics, personality, and the social ecosystem that would suit them. Athena does not follow a cookie-cutter approach; instead, they give mentorship on how the child can bring out the best and unique attributes about themself in college applications.', image: ParentTestimonials4, name: 'Moksha Sharma', subheading: 'University of Chicago, \'21 | Athena \'17' },
        { description: 'The biggest perk of working with Athena was that we, as parents, could comfortably take a step back. The phenomenal amount of attention to detail with which Athena prepared Aryaman for all his projects, essays, and other endeavors assured us that he was in the safest hands. From tailoring Aryaman according to his capabilities and personality to structuring every bit of the college experience according to his profile, each step of the process was perfectly handled.', image: ParentTestimonials1, name: 'Rajesh Sokhal', subheading: 'Parent of Aryaman Raj Sokhal | Cambridge University, \'24 | Athena \'20' },
        { description: 'When we connected with Athena, we learned that Athena doesn\'t catch the fish for the student; it teaches the student fishing. I have seen Tulip transform before my own eyes and I wish we had signed up with Athena in the 9th grade. The earlier you sign up, the more value you get! We were three years late.', image: ParentTestimonials2, name: 'Preeti Kaul', subheading: 'Parent of Ish Kaul | Princeton University, \'22 | Athena \'18' },
        { description: 'Athena has been a transformative experience for Samara.  She has always had a kind heart and a desire to make a difference in the world, and Athena has helped her focus and clarify her path forward. She has gained confidence, clarity, and her organizational skills have improved tremendously. Athena has also helped her evolve into a more well-rounded person, embracing diverse perspectives while having the courage to hold on to her own. I am grateful to Athena for their kindness, focus on each child\'s individuality, and for creating a fun environment where Samara could thrive. Athena has truly allowed Samara to blossom from the inside out.', image:ParentTestimonials5 , name: 'Ramona Jind', subheading: 'Mother of Smara Mohan | Athena \'22' },
        { description: 'I’m super proud! If I talk about Viraj before joining Athena, the current Viraj, the confidence he has today, and the way he speaks. I think the personal touch that Athena gives is crucial. It is the backbone of Athena. Having so many kids in one center and knowing intrinsic details about each student and the needs of each kid. The whole program is not one size fits all. The amount of customization you do for each kid is great!', image: PT6, name: 'Pooja Malik', subheading: 'Parent of Viraj Malik | Carnegie Mellon University \'27 | Athena ‘23' },
        { description: 'Throughout the college application process, she has definitely grown more mature and discovered herself far more than before, in terms of her college choices, her major, and what she wants to do in her college career. In her essays and applications, Athena made sure that Mehar put her point of view through the application. What really stood out was the detailed discussions with Jared and Chandra. The end result was amazing. Transformation can only happen when people put in genuine effort and time, and that’s what we got from Athena.', image:PT7 , name: 'Jatinder Bhasin', subheading: 'Parent of Mehar Bhasin | Dartmouth ‘27 | Athena ‘24' },
        { description: 'I remember we flew down from Mumbai to Delhi for a weekend because Abhishek had changed his early decision to UChicago. The Athena team sat with us to brainstorm his essay. We married a historical figure with modern technology, a concept perfectly aligned with Abhishek’s personality and interests. We really appreciate the gesture, given the fact that we were just one month away from the deadline.', image: PT8, name: 'Abhishek Mukherjee', subheading: 'Parent of Abhishek Mukherjee | University of Chicago, ‘27 | Athena ‘23' }
    ]

    function repeat(array) {
        const newArr = new Array(5).fill(array).flat()
        return newArr;
    }

    return (
        <div className='TESTIMONIALS-list-main-home-main'>
            <Carousel responsive={responsive}>
                {repeat(array).map(e => (
                    <div className='TESTIMONIALS-list-main-home'>
                        <div className='TESTIMONIALS-list-main-home-top-cont'>
                            <img src={Testi} className="normal" alt="" />
                            <p>
                                {e.description}
                            </p>
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                            <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                                <img src={e.image} className="normal" alt="" />
                            </div>
                            <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                                <h4>{e.name}</h4>
                                <p>{e.subheading}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default ParentsTestimonials