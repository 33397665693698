import React, { Component, Fragment, useState,useRef, useEffect } from "react";

import TextField from '@material-ui/core/TextField';

import { Container,Box,Grid,Button, Typography,useMediaQuery,useTheme,Dialog,List,ListItem, Divider, DialogTitle,  ListItemText } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
    schoolList,
    countryCodeList,
    regionList,
    referenceList,
    countries,
    stateList,
    countriesName,
    oneToOneStates,
    oneOnOneReferenceList,
    oneOnOneSchoolList
 } from "../utils/constant";
import axios from "axios";
import Lottie from 'react-lottie';
import animationData from '../lottie/meeting_scheduling_loader.json'
import bookingSession from '../img/booking-session.png'
import mobileBookingSession from '../img/mobile-booking-session.png'
import CalendlyForm from "./HubspotContactForm2";

import { makeStyles } from '@material-ui/core/styles';
import HubspotContactForm from "./HubspotContactForm";
import HubspotContactForm2 from "./HubspotContactForm2";

export const BaseContainer = (props) => {
    return (
        <Container maxWidth='lg'>
            <Box >
                {props.children}
            </Box>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    roundedTextField: {
      borderRadius: '25px',
      backgroundColor:'#fff',
       // Use the theme's border radius value
      '& .MuiOutlinedInput-root': {
        borderRadius: '25px', 
        boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.15)',
        '&:hover fieldset': {
            borderColor: '#0D3C42', // Color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#0D3C42', // Color on focus
          },
          '& .MuiAutocomplete-popupIndicator': {
            color:'white',
            marginTop:'10%',
            backgroundColor:'#0D3C42',

          },
          '& .MuiIconButton-label': {
            marginTop:'15%',
          }
        },
        
      
    },
    inputRoot: {
        height: '25px',
    },
    stickyTitle: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.appBar + 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },

  }));


const BookSession = () => {
    const classes = useStyles();
    const [state,setState] = useState({
        school:undefined,
        firstName:undefined,
        lastName:undefined,
        email:undefined,
        contactNumber:undefined,
        gradYear:undefined,
        country:undefined,
        city:undefined,
        state:undefined,
        reference:undefined,
        sendTextMssgNumber:undefined,
        error:false,
        redirectLink:undefined,
        contactNumberCountryCode:'+91 (India)',
        mssgNumberCountryCode:undefined,
        isLoadingEnable : false,
        errors:[],
        showLoader : false,
        open:{
            contactNumberCountryCode:false,
            gradYear:false,
            region:false,
            reference:false,
        },
    });
    const updateState = (val,key) => {
        setState(prev=>{
            return{
                ...prev,
                [key]:val,
            }
        });
    }

    const postData = () => {
        const phoneNumber = `${state.contactNumberCountryCode.split(" ")?.[0]}${state.contactNumber}`;
        const oneOnOneRegionBased = ((state.country === 'India' && oneToOneStates.includes(state.state)) || state.country !== 'India')
        const oneOnOneCond = !oneOnOneRegionBased ? (oneOnOneReferenceList.includes(state.reference) || oneOnOneSchoolList.includes(state.school))  :oneOnOneRegionBased;
        try{
            axios.post(`${process.env.REACT_APP_DELPHI_BASE_URL}api/v1/public/submit-form`, {
                email: state.email.trim(),
                firstName: state.firstName.trim(),
                lastName:state.lastName.trim(),
                graduationYear:state.gradYear,
                school:state.school,
                source:state.reference,
                phone:phoneNumber.trim(),
                state:state?.state?.trim(),
                city:state?.city?.trim(),
                country:state.country,
                type:oneOnOneCond ? 'oneOnOne' : 'fcs',
                })
                .then((response) => {
                    if(oneOnOneCond){
                        window.location.href = `https://meetings-eu1.hubspot.com/dikshant/trial-round-robin?firstName=${state.firstName}&lastName=${state.lastName}&email=${state.email}`  
                    }else{
                        window.location.href = `https://calendly.com/athenaeducation/athena-education-counseling-session-new?name=${state.firstName} ${state.lastName}&email=${state.email}&a1=${phoneNumber}`;     
                    }
                }).catch((error)=>{
                    console.log(error);
                });
           
           
        }catch(error){
            console.log(error);
        }
        
           
    
     
    }

    const isEmpty = (val) => {
       
        if (val === undefined ){
            return true;
        }else{
            const res =  val.trim().length<3; 
            if(res){
                return true;
            }
        }
        return false;
    }

    const namePattern = /^[a-zA-Z\s]+$/;
    const emailPattern = /^\s*[\w\s]+@[a-zA-Z0-9\s]+.[a-zA-Z\s.]{2,}\s*$/;
    const numberPattern = /^-?\d+(?:\.\d+)?$/;


    const validateName = (name) => {

        if(name === undefined ){
            return false;
        } else{
            const res =  name.trim().length<1; 
            if(res){
                return false;
            }
            return namePattern.test(name.trim());
        }
       
    };
    const validateNumber = (number) => {
        if(number === undefined ){
            return false;
        } else{
            const res =  number.trim().length<2; 
            if(res){
                return false;
            }
            return numberPattern.test(number.trim());
        }
    }
    
    const validateEmail = (email) => {
        if(email === undefined){
            return false;
        }else{
            const res =  email.trim().length<3; 
            if(res){
                return false;
            }
            return emailPattern.test(email.trim());
        } 
        
    }
    const validation = ()=>{

        const error = []
      
        if(!validateName(state.firstName) ) {
            error.push({key:'firstName',value:'Invalid Name'});
        }
        if(!validateName(state.lastName)){
            error.push({key:'lastName',value:'Invalid Name'});
        }
        if(isEmpty(state.contactNumberCountryCode) ){
            error.push({key:'contactNumberCountryCode',value:'Empty Country Code'});
        }
        if(!validateNumber(state.contactNumber)){
            error.push({key:'contactNumber',value:'Invalid Phone number'});
        }
        if(!validateEmail(state.email)){
            error.push({key:'email',value:'Invalid Email Address'});
        }
        if(isEmpty(state.school)){
            error.push({key:'school',value:'Invalid School Name'});
        }
        if(isEmpty(state.gradYear)){
            error.push({key:'gradYear',value:'Invalid Graduation Year'});
        }
        if(isEmpty(state.country)){
            error.push({key:'country',value:'Invalid country'});
        }
        if(isEmpty(state.city)){
            error.push({key:'city',value:'Invalid city'});
        }
        if(isEmpty(state.state) && state.country === 'India'){
            error.push({key:'state',value:'Invalid state'});
        }
        if(isEmpty(state.reference)){
            error.push({key:'reference',value:'Invalid reference'})
        }
       
        updateState(error,'errors');
        return error;
        
    }

    const renderLottieAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          };
          setTimeout(()=>{
            updateState(true,"showLoader")
          },4000);
        return(
            <Box style={{width:'100%',height:'100%'}}>
            <Typography style={{textAlign:'center',fontWeight:'400',fontSize:'16px'}}>Please Wait ... We are finding the best consultant for you. </Typography>
            <br/>
            <br />   
                
            <Typography style={{textAlign:'center',fontWeight:'600',fontSize:'16px'}}>  "Excellence is not an act, but a habit."
                </Typography>       <br /> 
                {
                    state.showLoader &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src="https://hora-ai.s3.ap-south-1.amazonaws.com/animation_200_lhirm499.gif" alt="My GIF" style={{height:'60px'}}/>
                    </div>

                }
                
               
            <Lottie 
                options={defaultOptions}
                height={300}
                width={400}
            />
            <Box style={{height:'200px'}}/>
            </Box>
        )
    }

    const updateOpenState = (key,value) => {
        setState(prev=>{
            return{
                ...prev,
                open:{...prev.open,[key]:value}
            }
        })
    }

    const handleScrollUp = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

    //   useEffect(()=>{
    //     validation();

    //   },[state])
    

    const renderMobileDropdown = (arr,dialogLabel,key,val,label) => {
        return (
            <Fragment>
                <Typography style={{fontFamily:'League Spartan',fontSize:'16px',fontWeight:'500',color:'#0D3C42',marginTop: label===undefined ? '25px':'0px' }}>{label}</Typography>
            
               <Button onClick={()=>{
                    updateOpenState(key,true);
               }} 
               
                style={{
                border: state.errors.find(item=>item.key === key) ? '0.2px solid #FF2E2E':'0.2px solid #979797',
                borderRadius: '20px',width:'100%',height:'40px',
                textTransform:'none',
                boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.15)',

               }}>
                <span style={{color: val ? '#0D3C42 ' :'#979797',fontSize:'14px'}}>{val ?? dialogLabel}</span></Button>
                {
                   state.errors.find(item=>item.key === key) && 
                   <span style={{color:'#FF2E2E',fontSize:'8px'}}>Required</span>
                }
              
                <Dialog 
                 fullWidth={'lg'}
                onClose={()=>{
                    updateOpenState(key,false);
                }} open={state.open?.[key]}>
                <DialogTitle className={classes.stickyTitle} style={{alignSelf:'center',width:'100%'}}>
                    <span style={{fontSize:'16px',fontFamily:'League Spartan',fontWeight:'400'}}>{dialogLabel}</span>
                    <Button  style={{position:'absolute',right:'0px',color:'#0D3C42'}} onClick={()=>{updateState(false,'open');}}>close</Button>
                </DialogTitle> 
                <List sx={{ pt: 0 }}>
                    {arr.map((item,index) => (
                            <>
                            <ListItem key={index}> 
                                <ListItemText style={{width:'100%'}} onClick={()=>{
                                    updateState(item,key);
                                    updateOpenState(key,false);
                                }}>
                                    <span style={{fontSize:'16px',fontFamily:'League Spartan',fontWeight:'300'}}>{item}</span>
                        
                                </ListItemText>
                           </ListItem>
                           <Divider/>
                           </> 
                    ))}
                    

                    
                </List>
                </Dialog>
            </Fragment>
        )
    }


    const renderTextField = (val, key,placeholder,label) => {
        return (
            <Fragment>
            <Typography style={{fontFamily:'League Spartan',fontSize:'16px',fontWeight:'500',color:'#0D3C42',marginTop: label===undefined ? '25px':'0px' }}>{label}</Typography>
            <TextField
                className={classes.roundedTextField}
                id="outlined-basic"
                variant="outlined"
                error={state.errors.find(item=>item.key === key)}
                size={'small'}
                helperText={state.errors.find(item=>item.key === key)? state.errors.find(item=>item.key === key).value : ''}
                onChange={({ target }) => updateState(target.value,key)}
                value={val}
                placeholder={placeholder}
                inputProps={{
                    style: {
                        height: '17.5px',
                        fontSize:'14px',
                       
                    }
                }}
                style={{ width: '100%' ,borderRadius:'20px',fontFamily:'League Spartan'}}
            />
            </Fragment>

        )
    }

    const tempSchoolList =[...oneOnOneSchoolList];
    

    const getCountryCodeList = () => {
        const arr = [];
        countries.map(item=>{
            arr.push(`${item.code} (${item.name})`);
        })
        return arr;
    }

    const getYearList = () => {
        const date = new Date();
        const year = date.getFullYear();
        return [`${year+1}`,`${year+2}`,`${year+3}`,`${year+4}`,'Other'];
    }

    const inputRef = useRef(null);
    const handleKeyDown = (event,key) => {
        // console.log(event.target.value,key)
        if (event.key === 'Enter') {
            updateState(event.target?.value,key) // Prevent the default action of the "Enter" key
        }
      };
    
  


    const renderDropdown = (arr,placeholder,val,key,label) => {
        if(state.school !== undefined && key === 'school'){
            arr.push(state.school);
        }
        return (
            <Fragment>

           <Typography style={{fontFamily:'League Spartan',fontWeight:'400',color:'#0D3C42',fontSize:'16px',}}>{label}</Typography>
            <Autocomplete
                
                freeSolo={key === 'school' ? true : false }
                className={classes.roundedTextField}
                // options={arr}
                options={key ==='school' ? arr :arr.filter((option) =>(state?.[key] && option.toLowerCase().startsWith(state?.[key].toLowerCase())) || (state?.[key] === undefined || state?.[key] === ''))}
                value={val}
                style={{borderRadius:'20px' }}
                size={'small'}
                renderOption={(option) => (
                        <Typography style={{fontSize:'14px',fontFamily:'League Spartan'}}>{option}</Typography>
                   
                )}
                getOptionLabel={(option) => option}
                renderInput={(params) => {
                    params.inputProps.style = {
                      fontSize: '14px',
                    };
                  
                    return (
                      <TextField
                        {...params}
                        error={state.errors.find((item) => item.key === key)}
                        helperText={state.errors.find((item) => item.key === key) ? state.errors.find((item) => item.key === key).value : ''}
                        placeholder={placeholder}
                        inputRef={inputRef}
                        onChange={({ target }) => {
                          if(target.value){
                                updateState(target.value, key);        
                          updateState(target.value, key);
                                updateState(target.value, key);        
                          }
                        }}
                        variant="outlined"
                        onKeyDown={(event) => {
                          handleKeyDown(event, key);
                        }}
                        // Rest of your TextField props
                      />
                    );
                  }}
                
                onInputChange={(event, value)=>{
                    updateState(value,key);
                }}
            />
             </Fragment>
        );
    }

    const handleClick = () => {
        if(validation().length === 0){
            // setState((prev)=>{
            //     return{
            //         ...prev,
            //         isLoadingEnable:true,
            //         error:true
            //     }
            // })
            // console.log(state?.redirectLink);
            // window.open(state?.redirectLink ?? 'https://athenaeducation.com');
            //This will post the date to hubspot.
            handleScrollUp();
            postData();
        }
    }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const renderDesktopView = () =>{
        return (
            <Grid container spacing={3}>
                
                    <Grid xs={6} item>
                        {/* {renderFixDropdown(state.name)} */}
                        {renderTextField(state.name,'firstName','First Name','First Name*')} 
                    </Grid>
                    <Grid xs={6} item>
                        {renderTextField(state.name,'lastName','Last Name','Last Name*')}
                    </Grid>
                    <Grid xs={6} item>
                        {renderTextField(state.email,'email','Email','Email*')}
                    </Grid>
                    <Grid xs={2} item>
                        {renderDropdown(getCountryCodeList(),'Country Code',state.contactNumberCountryCode,'contactNumberCountryCode','Phone Number*')}
                    </Grid>
                    <Grid xs={4} item>
                        {renderTextField(state.contactNumber,'contactNumber','Contact Number')}
                    </Grid>
                   
                    <Grid xs={state.country === 'India' ? 4:6} item>
                        {renderDropdown(countriesName,'Please select the country *',state.country,'country','Which country are you from?* ')}
                        {/* {renderTextField(state.region,'region','Please select the region of your residence *')} */}
                    </Grid>
                    
                    {
                        state.country === 'India' &&
                        <Grid xs={4} item>
                            {renderDropdown(stateList,'Please select your state *',state.state,'state','Which state are you from?* ')}
                            {/*  {renderTextField(state.city,'city','Which city are you from? *','Which city are you from?*')} */ }
                        </Grid>
                    }
                    <Grid xs={state.country === 'India' ? 4:6} item>
                        {renderTextField(state.city,'city','Please enter the city your residence *','Which city are you from ?*')} 
                    </Grid>
                    {
                        ((!isEmpty(state.country) && state.country !== 'India') || !isEmpty(state.state)) &&
                        <Fragment>
                        <Grid xs={6} item>
                            {
                                ((state.country === 'India' && oneToOneStates.includes(state.state)) || state.country !== 'India') ? 
                                    renderTextField(state.school,'school','Please Enter Your School','School details *')
                                    :  renderDropdown(tempSchoolList,'Please Enter / Select Your School',state.school,'school','School details*')
        
                            }
                        </Grid>
                        <Grid xs={6} item>
                            {renderDropdown(getYearList(),'High-school graduation year *',state.gradYear,'gradYear','High-school graduation year*')}
                            {/* {renderTextField(state.gradYear,'gradYear','High-school graduation year *')} */}
                        </Grid>
                        </Fragment>
                    }
                   
                    <Grid xs={12} item>
                        {renderDropdown(referenceList,'How did you hear about us? *',state.reference,'reference','How did you hear about us?*')}
                    </Grid>
                    {/* <Grid xs={4} item>
                        {renderDropdown(getCountryCodeList(),'Country Code',state.mssgNumberCountryCode,'mssgNumberCountryCode')}
                    </Grid> */}
                    {/* <Grid xs={8} item>
                        {renderTextField(state.sendTextMssgNumber,'sendTextMssgNumber','Send text messages to')}
                    </Grid> */}
        
                    <Grid xs={4} item style={{marginTop:'40px'}}>
                        <Button style={{backgroundColor:'#0D3C42',borderRadius:'20px',color:'#fff',width:'100%',height:'50px',fontSize:'14px',textTransform:'none'}}
                            onClick={handleClick}
                        >Submit</Button>
                    </Grid>
                    </Grid>
        )
    }

    const renderMobileView = () => {
        return (
            <Grid container spacing={3} style={{marginTop:'4%'}}> 
                        <Grid xs={6} item>
                            {/* {renderFixDropdown(state.name)} */}
                            {renderTextField(state.name,'firstName','First Name','First Name*')} 
                        </Grid>
                        <Grid xs={6} item>
                            {renderTextField(state.name,'lastName','Last Name','Last Name*')}
                        </Grid>
                        <Grid xs={12} item>
                            {renderTextField(state.email,'email','Email','Email*')}
                        </Grid>
                        <Grid xs={6} item>
                            {renderMobileDropdown(getCountryCodeList(),'Select Country','contactNumberCountryCode',state.contactNumberCountryCode,'Phone Number*')}
                            {/* {renderDropdown(getCountryCodeList(),'Country Code',state.contactNumberCountryCode,'contactNumberCountryCode','Phone Number*')} */}
                        </Grid>
                        <Grid xs={6} item>
                            {renderTextField(state.contactNumber,'contactNumber','Contact Number')}
                        </Grid>

                        <Grid xs={12} item>
                            {renderMobileDropdown(countriesName,'Please select the country *','country',state.country,'Which country are you from?* ')}
                        {/* {renderTextField(state.region,'region','Please select the region of your residence *')} */}
                        </Grid>
                        {
                        state.country === 'India' &&
                            <Grid xs={12} item>
                                {renderMobileDropdown(stateList,'Please select your state *','state',state.state,'Which state are you from?* ')}
                                {/*  {renderTextField(state.city,'city','Which city are you from? *','Which city are you from?*')} */ }
                            </Grid>
                        }
                         <Grid xs={12} item>
                            {renderTextField(state.city,'city','Please enter the city *','Which city are you from ?*')} 
                        </Grid>
                        {
                        ((!isEmpty(state.country) && state.country !== 'India') || !isEmpty(state.state)) &&
                        <Fragment>
                            <Grid xs={12} item>
                            {
                                ((state.country === 'India' && oneToOneStates.includes(state.state)) || state.country !== 'India') ? 
                                    renderTextField(state.school,'school','Please Enter Your School','School details*')
                                    :  renderDropdown(tempSchoolList,'Please Enter / Select Your School Name',state.school,'school','School details*')
        
                            }
                            </Grid>
                            <Grid xs={12} item>
                                {renderMobileDropdown(getYearList(),'Select Graduation Year','gradYear',state.gradYear,'High-school graduation year *')}
                            
                            </Grid>
                        </Fragment>
                        }

                        <Grid xs={12} item>
                            {renderMobileDropdown(referenceList,'Select*','reference',state.reference,'How did you hear about us? * ')}
                         
                            {/* {renderDropdown(referenceList,'How did you hear about us? *',state.reference,'reference','How did you hear about us?*')} */}
                            {/* {renderTextField(state.reference,'reference','How did you hear about us? *')} */}
                        </Grid>

            
                        <Grid xs={12} item style={{marginTop:'40px'}}>
                            <Button style={{backgroundColor:'#0D3C42',borderRadius:'20px',color:'#fff',width:'100%',height:'50px',fontSize:'14px',textTransform:'none'}}
                                onClick={handleClick}
                            >Submit</Button>
                        </Grid>
                    </Grid>
        )
    }

    const renderInstruction = () => {
        return(
            <Box style={{
                background: "rgba(255, 255, 255, 0.15)",
                border: "1px solid #0D3C42",
                boxShadow: "0px 0px 27px -1px rgba(0, 0, 0, 0.1)",
                padding:'40px',
                width:'100%',
                lineHeight:'28px',
                borderRadius: "20px"}}>
                    <Typography style={{color:'#000000',fontWeight:'600',fontSize:'26px',fontFamily:'League Spartan',lineHeight:'28px'}}>Here is an overview of what happens in a consultation session.</Typography>
                    <Typography style={{color:'#000000',fontWeight:'500',fontSize:'16px',marginTop:'20px',fontFamily:'League Spartan',lineHeight:'28px'}}>1. What it takes to get into Ivy Leagues and other top universities around the world.
                                <br/><br/>2. How Athena develops student profiles and applications (including successful case studies).
                                <br/><br/>3. Receive feedback on your own profile and how we will elevate it to the next level.</Typography>
    
    
            </Box>
        )
    }
    return (
        <Fragment>
            {/* <Box style={{position:'absolute',zIndex:'-1',marginTop:'-2px'}}>
                <img src ={BannerOne}/>
            </Box> */}
        <img src={isMobile? mobileBookingSession: bookingSession} style={{width:'100%'}} />
        <BaseContainer>
       
            {/* <Box style={{padding:'2%',width:'120%',marginLeft:'-10%'}}>
            <Grid container spacing={1} style={{padding:'1%',paddingInline:'4%'}}>
            { state.isLoadingEnable ?
                renderLottieAnimation()
            :   
                <Fragment>
                <Grid xs={ 12} style={{paddingInline:'4%',marginTop:isMobile ?'20px':'10px'}} item>
                    <Grid xs={12} item>
                        <span style={{fontSize:isMobile ? '30px':'50px',color:'#0D3C42',fontFamily:'League Spartan',fontWeight:'300',lineHeight:'28px'}}>Your journey to elite college admissions starts here.</span>
                        <Typography style={{marginTop:"20px",fontSize:'22px',fontWeight:'300',fontFamily:'League Spartan',color:'#0D3C42',lineHeight:'24px'}}>
                            Schedule a meeting with an admissions expert today.
                        </Typography>
                    
                        <br/>
                    </Grid>
                   {isMobile ? renderMobileView(): renderDesktopView()}
                
                </Grid>
               
                <Grid xs={12} item>
                    <Box style={{height:'100px'}}/>
                </Grid>
                </Fragment>
            }
            
            </Grid>
            </Box>     */}
            <span>Test Page</span>
            <div style={{marginTop:'5%'}}>
                <HubspotContactForm2 formId={'83d4a3c8-b985-422b-92a7-a391820df9cb'}/>
            </div>
           
           
           
        </BaseContainer >
        </Fragment>
    );
}

export default BookSession;