import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'react-ig-feed/dist/index.css'
import Play from '../img/play.png';
import Fancybox from "./fancybox.js"; 
import Map from '../img/map.png';
import Clock from '../img/clock.png';
import Cal from '../img/cla.png';
import { Link } from "react-router-dom";
import ArrowOne from '../img/arrow-1.png';
import Arrowtwo from '../img/arrow-2.png'; 
import { Button } from '@material-ui/core';

function Events() {
    const responsive = { 
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
   
    
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(4);

    const events = [
        {
            "_id": "64215c662a4dd167c1bbf277",
            "eventname": "FIND YOUR IKIGAI",
            "date": "2023-03-23",
            "time": "17:38",
            "place": "Zoom Session",
            "alt": "",
            "image": "public/uploads/events/events45247dd7df5b13742773a4c212d285cb.jpg",
            "link":'/us-fcs',
            "__v": 0
        },
        // {
        //     "_id": "64215d8b2a4dd167c1bbf36b",
        //     "eventname": "BUILD YOUR HUMAN BRAND",
        //     "date": "2023-03-21",
        //     "time": "14:42",
        //     "place": "Athena Campus, Gurugram",
        //     "alt": "",
        //     "image": "public/uploads/events/events752fd6cf3edbb37abde428619d9160ef.jpg",
        //     "__v": 0
        // },
        // {
        //     "_id": "64215de12a4dd167c1bbf3ab",
        //     "eventname": "THE UNDERGRAD XPERIENCE",
        //     "date": "2023-03-31",
        //     "time": "14:44",
        //     "place": "Athena Campus, Gurugram",
        //     "alt": "",
        //     "image": "public/uploads/events/events658473f65c9cf03a5996585960f26755.jpg",
        //     "__v": 0
        // },
        // {
        //     "_id": "64215e3e2a4dd167c1bbf403",
        //     "eventname": "TIME MANAGEMENT: BEATING PROCRASTINATION",
        //     "date": "2023-03-24",
        //     "time": "14:44",
        //     "place": "Athena Campus, Gurugram",
        //     "alt": "",
        //     "image": "public/uploads/events/eventsf64ac08ad7bfe7bc56a07570bdb07bcf.jpg",
        //     "__v": 0
        // },
        // {
        //     "_id": "64215e802a4dd167c1bbf443",
        //     "eventname": "MEET A HARVARD ADMISSIONS OFFICER",
        //     "date": "2023-03-30",
        //     "time": "14:46",
        //     "place": "Athena Campus, Gurugram",
        //     "alt": "",
        //     "image": "public/uploads/events/eventsdf7b92886a86b2d331b7e6267c288305.jpg",
        //     "__v": 0
        // }
    ];
    //   setLoading(true);
    // setPosts(events);
    //   setLoading(false);
    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/events`);
            setPosts(events);
            setLoading(false);
        };

        fetchPosts();
    }, []);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    
    return (
       <>
      
            {
                posts.map(post => ( 
                   
                    <div key={post.id} > 
                     
                         <div style={{padding:'20px',borderRadius:'20px'}} className={'boxShadow'}>
                            <div className='upcoming-Events-mian-middle-frame-image'>
                            <img src={process.env.REACT_APP_API_BASE_URL + post.image} />
                            </div>
                            <div className='upcoming-Events-mian-middle-frame-bottom'>
                                <h4>{post.eventname}</h4>
                               
                                <div className=''>
                                <ul>
                                    <li>
                                        <img src={Cal} />
                                        <span>TBD</span>
                                    </li>
                                    <li>
                                        <img src={Clock} />
                                        <span>TBD</span>
                                    </li>
                                   
                                    <li style={{display:'flex',flexDirection:'row',width:'180%'}}>
                                        <div style={{width:'100%'}}>
                                            <img src={Map} style={{height:'20px',width:'15px'}}/>
                                            <span>{post.place}</span>
                                        </div>
                                    </li>
                                       
                                    <li>
                                        
                                        <Button style={{borderRadius:'20px',backgroundColor:'#0D3C42',color:'#fff',textTransform:'none',width:'80%',marginTop:'10px'}} onClick={
                                            ()=>{
                                                window.location.href = "/us-fcs"
                                            }
                                        }>Sign Up</Button>
                                    </li>
                                    
                                </ul>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                     
                ))
            }
        
         </>
    );
};
   
export default Events;