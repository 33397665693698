import React, {Fragment} from "react";
import bookingSession from '../img/booking-session.png'
import mobileBookingSession from '../img/mobile-booking-session.png'
import {Typography, useMediaQuery, useTheme} from "@material-ui/core";
import ZohoForm from "./ZohoContactForm";

const BookingSession = () => {


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Fragment>
            {/* <Box style={{position:'absolute',zIndex:'-1',marginTop:'-2px'}}>
                <img src ={BannerOne}/>
            </Box> */}

            <img src={isMobile? mobileBookingSession: bookingSession} style={{width:'100%'}} />
            <div style={{paddingInline: '10%', marginBlock: '5%', gap: '20px'}}>
                <span style={{
                    fontSize: isMobile ? '30px' : '50px',
                    color: '#0D3C42',
                    fontFamily: 'League Spartan',
                    fontWeight: '300',
                    lineHeight: '28px'
                }}>Your journey to elite college admissions starts here.</span>
                <Typography style={{
                    marginTop: "20px",
                    fontSize: '22px',
                    fontWeight: '300',
                    fontFamily: 'League Spartan',
                    color: '#0D3C42',
                    lineHeight: '24px'
                }}>
                    Schedule a meeting with an admissions expert today.
                </Typography>
                <div style={{marginTop: '5%'}}>
                    <ZohoForm/>
            </div>

        </div>


</Fragment>
)
    ;
}

export default BookingSession;