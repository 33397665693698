import React, { useEffect, useState } from 'react';
import Fancybox from "./fancybox.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import play from '../img/play.png';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

export default function VideoTestimonials() {

    const [youtubePlaylist, setYoutubePlaylist] = useState([]);

    async function getYoutubePlaylist() {
        const playlistId = 'PL1W5oMYSMNnkCEQ8tubZNZ4tP4tJI3Mai';
        const maxResults = '100'
        const res = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=${maxResults}&key=AIzaSyAY_0NrdhOmsHaiwzyoVBfp84o51ZTs-XU`)
        const json = await res.json()
        console.log(json.items)
        setYoutubePlaylist(json.items)
    }

    useEffect(() => {
        console.log(process.env.REACT_APP_YOUTUBE_API_KEY)

        getYoutubePlaylist()
    }, [])

    return (
        <div className='container'>
            <div className='Video-Testimonials-page-main'>
                <Carousel responsive={responsive}>
                    {youtubePlaylist?.map((video) => (
                        <div key={video.id}>
                            <Fancybox options={{ dragToClose: false }}>
                                <a data-fancybox="gallery" href={`https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`}>
                                    <div className='Video-Testimonials-page2'>
                                        <img className='top' style={{ objectFit: 'cover', height: '110px' }} src={video.snippet.thumbnails.high.url} alt="" />
                                        <h5><img src={play} alt="" /></h5>
                                    </div>
                                </a>
                            </Fancybox>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>

    )
}