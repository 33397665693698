import React, { useState, useEffect } from "react";
import axios from "axios";
import SliderInsta from "./SliderInsta";

const Instagram = () => {
  const [instaPosts, setInstaPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}api/get-instagram-posts`
        );
        if(response?.status===200)
          setInstaPosts(response?.data)
      } catch (error) {
        console.log(error)
      }
    };
    fetchPosts();
  }, []);
  
  return (
    <div className="Instagram-bottom-section">
      <SliderInsta instaPosts={instaPosts}/>
    </div>
  );
};

export default Instagram;