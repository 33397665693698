import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Testi from './img/testi.png';
import Sukriti from './img/testimonial/student/Sukriti Ojha 2023 Icon.png'
import Eesha from './img/testimonial/student/Eesha Garimella 83_83.png'
import Taarush from './img/testimonial/student/Taarush Grover 83_83.png'
import Agastyaa from './img/testimonial/student/Agastyaa Vishwanath 83_83.png'
import Isha from './img/testimonial/student/Isha Jain 83_83.png'
import Aryan from './img/testimonial/student/Aryan Agarwal 83_83.png'
import Saumya from './img/testimonial/student/Saumya Chaudhry 83_83.png'
import Avihan from './img/testimonial/student/Avihan Jain 83_83.png'
import Anmol from './img/testimonial/student/Anmol Jain 83_83.png'
import Viraj from './img/testimonial/student/Viraj Malik 83_83.png'
import Mehar from './img/testimonial/student/Mehar Bhasin 83_83.png'

const Testimonials = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    }

    return (
        <div className='TESTIMONIALS-list-main-home-main'>
            <Carousel responsive={responsive} >



                {/* iteration 1 */}



                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena made me understand—at almost every step—how my projects aligned with the values I stand for and the vision I had for myself. I took the call on every aspect of my application, but their advice and support were indispensable to any stride I could take. They sculpted my heightened imagination into concrete steps that could put forth my true “brand” and eventually make my application seem impressive yet genuine and personal.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Sukriti} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Sukriti Ojha</h4>
                            <p>Yale University ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>One of the projects that I did with Athena was breast cancer surveys and screening camps for rural Indian women in Karnataka. This was a multi-step project, and it pushed me to my limits. When I joined Athena, I had different things that I was interested in and different existing extracurriculars, but there was no common thread. Athena gave me direction, bound them all together, and gave me a solid path forward.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Eesha} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Eesha Garimella</h4>
                            <p>UC Berkeley ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena understood my fit. I wanted to be in a place with an entrepreneurial environment that fosters creativity and my interdisciplinary nature while also having a great academic community. During my essay ideation process, they captured my multifaceted nature; each essay targeted a specific aspect of my personality. Athena made me think about my authentic self. They honed that individuality in my essays.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Taarush} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Taarush Grover</h4>
                            <p>Stanford University, ‘28 | Athena ‘24</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>It had been my dream to write a research paper, but I thought I'd have to wait until my third or fourth year in college. I came to Athena and found out that they have a full-fledged department for research. Athena will adapt to exactly what you want! They are a bank of wisdom, coming in with your problem, and they're there to solve it.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Agastyaa} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Agastyaa Vishwanath</h4>
                            <p>Imperial ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>They don't really try to fit you in a box and I think that's what works when you know you're applying to colleges as well because they want to see you for your most authentic self.The support and personalized attention from Athena made a significant difference in my journey. I felt guided and supported in discovering and showcasing my unique strengths and interests, which ultimately helped me stand out in my college applications.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Isha} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Isha Jain</h4>
                            <p>Stanford '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena helped me really understand what I'm passionate about and also what I would like to engage with in the next four years of my life and beyond college. They gave a common theme to my profile, the extracurriculars that would not only stand out on my resume,  but also play into my passions. So while deciding on my research projects, the kind of startup I wanted to do, or even kind of subjects I want to take at school,  Athena really helped me create that perfect blend that allowed me to think that yeah, this is going to look really good on my resume and on my application.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Aryan} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Aryan Agarwal</h4>
                            <p>Yale'27 | Athena'23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena helped me discover my true self.  Initially, I was scared but excited about finding a supportive community to grow with. Athena's sessions, meetings, and writing clubs were pivotal, culminating in the joyful moment of college acceptance. They guided me away from following my brother's footsteps and helped me realize my individuality, leading me to a college that perfectly fits me. Despite being set on a design school, Athena broadened my perspective to consider liberal arts. They helped me see the significance in my life experiences, transforming seemingly insignificant details into compelling essay content. Athena's approach felt like a walk through a meadow, encouraging collaboration, boldness, and creativity.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Saumya} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Saumya Chaudhry</h4>
                            <p>Barnard '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena has been instrumental in helping me stand on my own feet. The Saturday Academy workshops were pivotal the teachings and presentations helped me build confidence and develop essential skills. The essays and deep conversations with Chandra Ma’am led me to introspect, uncovering my strengths and weaknesses, and fostering personal growth. Participating in the First Robotics Competition with Athena, where I led the team and became a Dean's List member, was a significant challenge. It taught me true leadership, especially while managing a diverse team. Athena's guidance on profile building was not about ticking boxes but about finding and pursuing my passions.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Avihan} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Avihan Jain</h4>
                            <p>Duke '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>The most challenging extracurricular for me was the research work, as I wrote three research papers during high school. Conducting primary research, interviews, and surveys, especially with limited exposure to academic writing in a school, pushed me the most and helped me grow significantly. Writing numerous essays and using Athena’s AOS templates allowed me to reflect deeply on my experiences and relationships, helping me understand myself better. Getting into UPenn during the early decision round was a significant moment, and Athena's encouragement to complete my regular decision essays early alleviated a lot of stress.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Anmol} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Anmol Jain</h4>
                            <p>UPenn '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Building a strong profile for college applications takes time, and Athena helped me refine my projects and discover my passions, ultimately translating them onto my college application. Athena kept me motivated and on track, the application process, although overwhelming at times, became manageable. Athena's program allowed me to explore fields like physics and economics, leading me to renewable technology. The Athena Research Program, particularly challenging with its intensive sessions and new learnings, helped me publish a paper, which was a significant achievement.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Viraj} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Viraj Malik</h4>
                            <p>CMU '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Throughout the college process, several factors kept me going, including the unwavering support of my parents and the entire team at Athena. There were many times when I felt confused and uncertain, but everyone at Athena were always there to guide me. Writing college essays helped me gain clarity on what I genuinely enjoy and want to pursue. This self-discovery process, although challenging, was incredibly valuable.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Mehar} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Mehar Bhasin</h4>
                            <p>Dartmouth '27 | Athena '23</p>
                        </div>
                    </div>
                </div>




                {/* iteration 2 */}





                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena made me understand—at almost every step—how my projects aligned with the values I stand for and the vision I had for myself. I took the call on every aspect of my application, but their advice and support were indispensable to any stride I could take. They sculpted my heightened imagination into concrete steps that could put forth my true “brand” and eventually make my application seem impressive yet genuine and personal.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Sukriti} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Sukriti Ojha</h4>
                            <p>Yale University ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>One of the projects that I did with Athena was breast cancer surveys and screening camps for rural Indian women in Karnataka. This was a multi-step project, and it pushed me to my limits. When I joined Athena, I had different things that I was interested in and different existing extracurriculars, but there was no common thread. Athena gave me direction, bound them all together, and gave me a solid path forward.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Eesha} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Eesha Garimella</h4>
                            <p>UC Berkeley ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena understood my fit. I wanted to be in a place with an entrepreneurial environment that fosters creativity and my interdisciplinary nature while also having a great academic community. During my essay ideation process, they captured my multifaceted nature; each essay targeted a specific aspect of my personality. Athena made me think about my authentic self. They honed that individuality in my essays.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Taarush} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Taarush Grover</h4>
                            <p>Stanford University, ‘28 | Athena ‘24</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>It had been my dream to write a research paper, but I thought I'd have to wait until my third or fourth year in college. I came to Athena and found out that they have a full-fledged department for research. Athena will adapt to exactly what you want! They are a bank of wisdom, coming in with your problem, and they're there to solve it.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Agastyaa} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Agastyaa Vishwanath</h4>
                            <p>Imperial ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>They don't really try to fit you in a box and I think that's what works when you know you're applying to colleges as well because they want to see you for your most authentic self.The support and personalized attention from Athena made a significant difference in my journey. I felt guided and supported in discovering and showcasing my unique strengths and interests, which ultimately helped me stand out in my college applications.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Isha} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Isha Jain</h4>
                            <p>Stanford '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena helped me really understand what I'm passionate about and also what I would like to engage with in the next four years of my life and beyond college. They gave a common theme to my profile, the extracurriculars that would not only stand out on my resume,  but also play into my passions. So while deciding on my research projects, the kind of startup I wanted to do, or even kind of subjects I want to take at school,  Athena really helped me create that perfect blend that allowed me to think that yeah, this is going to look really good on my resume and on my application.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Aryan} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Aryan Agarwal</h4>
                            <p>Yale'27 | Athena'23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena helped me discover my true self.  Initially, I was scared but excited about finding a supportive community to grow with. Athena's sessions, meetings, and writing clubs were pivotal, culminating in the joyful moment of college acceptance. They guided me away from following my brother's footsteps and helped me realize my individuality, leading me to a college that perfectly fits me. Despite being set on a design school, Athena broadened my perspective to consider liberal arts. They helped me see the significance in my life experiences, transforming seemingly insignificant details into compelling essay content. Athena's approach felt like a walk through a meadow, encouraging collaboration, boldness, and creativity.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Saumya} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Saumya Chaudhry</h4>
                            <p>Barnard '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena has been instrumental in helping me stand on my own feet. The Saturday Academy workshops were pivotal the teachings and presentations helped me build confidence and develop essential skills. The essays and deep conversations with Chandra Ma’am led me to introspect, uncovering my strengths and weaknesses, and fostering personal growth. Participating in the First Robotics Competition with Athena, where I led the team and became a Dean's List member, was a significant challenge. It taught me true leadership, especially while managing a diverse team. Athena's guidance on profile building was not about ticking boxes but about finding and pursuing my passions.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Avihan} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Avihan Jain</h4>
                            <p>Duke '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>The most challenging extracurricular for me was the research work, as I wrote three research papers during high school. Conducting primary research, interviews, and surveys, especially with limited exposure to academic writing in a school, pushed me the most and helped me grow significantly. Writing numerous essays and using Athena’s AOS templates allowed me to reflect deeply on my experiences and relationships, helping me understand myself better. Getting into UPenn during the early decision round was a significant moment, and Athena's encouragement to complete my regular decision essays early alleviated a lot of stress.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Anmol} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Anmol Jain</h4>
                            <p>UPenn '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Building a strong profile for college applications takes time, and Athena helped me refine my projects and discover my passions, ultimately translating them onto my college application. Athena kept me motivated and on track, the application process, although overwhelming at times, became manageable. Athena's program allowed me to explore fields like physics and economics, leading me to renewable technology. The Athena Research Program, particularly challenging with its intensive sessions and new learnings, helped me publish a paper, which was a significant achievement.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Viraj} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Viraj Malik</h4>
                            <p>CMU '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Throughout the college process, several factors kept me going, including the unwavering support of my parents and the entire team at Athena. There were many times when I felt confused and uncertain, but everyone at Athena were always there to guide me. Writing college essays helped me gain clarity on what I genuinely enjoy and want to pursue. This self-discovery process, although challenging, was incredibly valuable.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Mehar} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Mehar Bhasin</h4>
                            <p>Dartmouth '27 | Athena '23</p>
                        </div>
                    </div>
                </div>





                {/* iteration 3 */}





                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena made me understand—at almost every step—how my projects aligned with the values I stand for and the vision I had for myself. I took the call on every aspect of my application, but their advice and support were indispensable to any stride I could take. They sculpted my heightened imagination into concrete steps that could put forth my true “brand” and eventually make my application seem impressive yet genuine and personal.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Sukriti} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Sukriti Ojha</h4>
                            <p>Yale University ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>One of the projects that I did with Athena was breast cancer surveys and screening camps for rural Indian women in Karnataka. This was a multi-step project, and it pushed me to my limits. When I joined Athena, I had different things that I was interested in and different existing extracurriculars, but there was no common thread. Athena gave me direction, bound them all together, and gave me a solid path forward.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Eesha} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Eesha Garimella</h4>
                            <p>UC Berkeley ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena understood my fit. I wanted to be in a place with an entrepreneurial environment that fosters creativity and my interdisciplinary nature while also having a great academic community. During my essay ideation process, they captured my multifaceted nature; each essay targeted a specific aspect of my personality. Athena made me think about my authentic self. They honed that individuality in my essays.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Taarush} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Taarush Grover</h4>
                            <p>Stanford University, ‘28 | Athena ‘24</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>It had been my dream to write a research paper, but I thought I'd have to wait until my third or fourth year in college. I came to Athena and found out that they have a full-fledged department for research. Athena will adapt to exactly what you want! They are a bank of wisdom, coming in with your problem, and they're there to solve it.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Agastyaa} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Agastyaa Vishwanath</h4>
                            <p>Imperial ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>They don't really try to fit you in a box and I think that's what works when you know you're applying to colleges as well because they want to see you for your most authentic self.The support and personalized attention from Athena made a significant difference in my journey. I felt guided and supported in discovering and showcasing my unique strengths and interests, which ultimately helped me stand out in my college applications.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Isha} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Isha Jain</h4>
                            <p>Stanford '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena helped me really understand what I'm passionate about and also what I would like to engage with in the next four years of my life and beyond college. They gave a common theme to my profile, the extracurriculars that would not only stand out on my resume,  but also play into my passions. So while deciding on my research projects, the kind of startup I wanted to do, or even kind of subjects I want to take at school,  Athena really helped me create that perfect blend that allowed me to think that yeah, this is going to look really good on my resume and on my application.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Aryan} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Aryan Agarwal</h4>
                            <p>Yale'27 | Athena'23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena helped me discover my true self.  Initially, I was scared but excited about finding a supportive community to grow with. Athena's sessions, meetings, and writing clubs were pivotal, culminating in the joyful moment of college acceptance. They guided me away from following my brother's footsteps and helped me realize my individuality, leading me to a college that perfectly fits me. Despite being set on a design school, Athena broadened my perspective to consider liberal arts. They helped me see the significance in my life experiences, transforming seemingly insignificant details into compelling essay content. Athena's approach felt like a walk through a meadow, encouraging collaboration, boldness, and creativity.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Saumya} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Saumya Chaudhry</h4>
                            <p>Barnard '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena has been instrumental in helping me stand on my own feet. The Saturday Academy workshops were pivotal the teachings and presentations helped me build confidence and develop essential skills. The essays and deep conversations with Chandra Ma’am led me to introspect, uncovering my strengths and weaknesses, and fostering personal growth. Participating in the First Robotics Competition with Athena, where I led the team and became a Dean's List member, was a significant challenge. It taught me true leadership, especially while managing a diverse team. Athena's guidance on profile building was not about ticking boxes but about finding and pursuing my passions.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Avihan} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Avihan Jain</h4>
                            <p>Duke '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>The most challenging extracurricular for me was the research work, as I wrote three research papers during high school. Conducting primary research, interviews, and surveys, especially with limited exposure to academic writing in a school, pushed me the most and helped me grow significantly. Writing numerous essays and using Athena’s AOS templates allowed me to reflect deeply on my experiences and relationships, helping me understand myself better. Getting into UPenn during the early decision round was a significant moment, and Athena's encouragement to complete my regular decision essays early alleviated a lot of stress.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Anmol} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Anmol Jain</h4>
                            <p>UPenn '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Building a strong profile for college applications takes time, and Athena helped me refine my projects and discover my passions, ultimately translating them onto my college application. Athena kept me motivated and on track, the application process, although overwhelming at times, became manageable. Athena's program allowed me to explore fields like physics and economics, leading me to renewable technology. The Athena Research Program, particularly challenging with its intensive sessions and new learnings, helped me publish a paper, which was a significant achievement.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Viraj} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Viraj Malik</h4>
                            <p>CMU '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Throughout the college process, several factors kept me going, including the unwavering support of my parents and the entire team at Athena. There were many times when I felt confused and uncertain, but everyone at Athena were always there to guide me. Writing college essays helped me gain clarity on what I genuinely enjoy and want to pursue. This self-discovery process, although challenging, was incredibly valuable.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Mehar} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Mehar Bhasin</h4>
                            <p>Dartmouth '27 | Athena '23</p>
                        </div>
                    </div>
                </div>





                {/* iteration 4 */}







                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena made me understand—at almost every step—how my projects aligned with the values I stand for and the vision I had for myself. I took the call on every aspect of my application, but their advice and support were indispensable to any stride I could take. They sculpted my heightened imagination into concrete steps that could put forth my true “brand” and eventually make my application seem impressive yet genuine and personal.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Sukriti} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Sukriti Ojha</h4>
                            <p>Yale University ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>One of the projects that I did with Athena was breast cancer surveys and screening camps for rural Indian women in Karnataka. This was a multi-step project, and it pushed me to my limits. When I joined Athena, I had different things that I was interested in and different existing extracurriculars, but there was no common thread. Athena gave me direction, bound them all together, and gave me a solid path forward.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Eesha} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Eesha Garimella</h4>
                            <p>UC Berkeley ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena understood my fit. I wanted to be in a place with an entrepreneurial environment that fosters creativity and my interdisciplinary nature while also having a great academic community. During my essay ideation process, they captured my multifaceted nature; each essay targeted a specific aspect of my personality. Athena made me think about my authentic self. They honed that individuality in my essays.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Taarush} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Taarush Grover</h4>
                            <p>Stanford University, ‘28 | Athena ‘24</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>It had been my dream to write a research paper, but I thought I'd have to wait until my third or fourth year in college. I came to Athena and found out that they have a full-fledged department for research. Athena will adapt to exactly what you want! They are a bank of wisdom, coming in with your problem, and they're there to solve it.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Agastyaa} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Agastyaa Vishwanath</h4>
                            <p>Imperial ‘27 | Athena ‘23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>They don't really try to fit you in a box and I think that's what works when you know you're applying to colleges as well because they want to see you for your most authentic self.The support and personalized attention from Athena made a significant difference in my journey. I felt guided and supported in discovering and showcasing my unique strengths and interests, which ultimately helped me stand out in my college applications.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Isha} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Isha Jain</h4>
                            <p>Stanford '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena helped me really understand what I'm passionate about and also what I would like to engage with in the next four years of my life and beyond college. They gave a common theme to my profile, the extracurriculars that would not only stand out on my resume,  but also play into my passions. So while deciding on my research projects, the kind of startup I wanted to do, or even kind of subjects I want to take at school,  Athena really helped me create that perfect blend that allowed me to think that yeah, this is going to look really good on my resume and on my application.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Aryan} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Aryan Agarwal</h4>
                            <p>Yale'27 | Athena'23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena helped me discover my true self.  Initially, I was scared but excited about finding a supportive community to grow with. Athena's sessions, meetings, and writing clubs were pivotal, culminating in the joyful moment of college acceptance. They guided me away from following my brother's footsteps and helped me realize my individuality, leading me to a college that perfectly fits me. Despite being set on a design school, Athena broadened my perspective to consider liberal arts. They helped me see the significance in my life experiences, transforming seemingly insignificant details into compelling essay content. Athena's approach felt like a walk through a meadow, encouraging collaboration, boldness, and creativity.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Saumya} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Saumya Chaudhry</h4>
                            <p>Barnard '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Athena has been instrumental in helping me stand on my own feet. The Saturday Academy workshops were pivotal the teachings and presentations helped me build confidence and develop essential skills. The essays and deep conversations with Chandra Ma’am led me to introspect, uncovering my strengths and weaknesses, and fostering personal growth. Participating in the First Robotics Competition with Athena, where I led the team and became a Dean's List member, was a significant challenge. It taught me true leadership, especially while managing a diverse team. Athena's guidance on profile building was not about ticking boxes but about finding and pursuing my passions.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Avihan} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Avihan Jain</h4>
                            <p>Duke '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>The most challenging extracurricular for me was the research work, as I wrote three research papers during high school. Conducting primary research, interviews, and surveys, especially with limited exposure to academic writing in a school, pushed me the most and helped me grow significantly. Writing numerous essays and using Athena’s AOS templates allowed me to reflect deeply on my experiences and relationships, helping me understand myself better. Getting into UPenn during the early decision round was a significant moment, and Athena's encouragement to complete my regular decision essays early alleviated a lot of stress.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Anmol} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Anmol Jain</h4>
                            <p>UPenn '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Building a strong profile for college applications takes time, and Athena helped me refine my projects and discover my passions, ultimately translating them onto my college application. Athena kept me motivated and on track, the application process, although overwhelming at times, became manageable. Athena's program allowed me to explore fields like physics and economics, leading me to renewable technology. The Athena Research Program, particularly challenging with its intensive sessions and new learnings, helped me publish a paper, which was a significant achievement.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Viraj} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Viraj Malik</h4>
                            <p>CMU '27 | Athena '23</p>
                        </div>
                    </div>
                </div>

                <div className='TESTIMONIALS-list-main-home'>
                    <div className='TESTIMONIALS-list-main-home-top-cont'>
                        <img src={Testi} className="normal" alt="" />
                        <p>Throughout the college process, several factors kept me going, including the unwavering support of my parents and the entire team at Athena. There were many times when I felt confused and uncertain, but everyone at Athena were always there to guide me. Writing college essays helped me gain clarity on what I genuinely enjoy and want to pursue. This self-discovery process, although challenging, was incredibly valuable.
                        </p>
                    </div>
                    <div className='TESTIMONIALS-list-main-home-top-cont-img'>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-left'>
                            <img src={Mehar} className="normal" alt="" />
                        </div>
                        <div className='TESTIMONIALS-list-main-home-top-cont-img-right'>
                            <h4>Mehar Bhasin</h4>
                            <p>Dartmouth '27 | Athena '23</p>
                        </div>
                    </div>
                </div>




            </Carousel>
        </div>

    )
}

export default Testimonials