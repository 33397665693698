import React, { useEffect } from 'react';
import {
    useLocation
} from "react-router-dom";

const ThankYou = () => {
    const params = new URLSearchParams(useLocation().search)
    const encodedRedirectUrl = params.get('redirect')
    const redirectUrl = decodeURIComponent(encodedRedirectUrl)
    const email = new URL(redirectUrl).searchParams.get('email')
    console.log(email)
    console.log(window)

    useEffect(()=>{
        window.dataLayer = window.dataLayer || [];
        if(email){
            window.dataLayer.push({
                'event': 'book-a-session-formSubmission',
                'email': email
            })
            console.log('data layer updated')
            console.log(window.dataLayer)
        }
    }, [email])

    setTimeout(()=>{
        window.location.href = redirectUrl
    }, 2000)
    return (
        <div>
            <span style={{fontSize: '30px', paddingInline: '40px', marginBlock: '50px'}}>Thank you</span>
        </div>
    )
}

export default ThankYou