import React, { Fragment,useState } from "react";
import { Box,Button,Container,Grid, Typography,Dialog,DialogTitle } from "@material-ui/core";
import lawBackground from '../img/law-background.jpg';
import PresentByGroup from '../img/presented-by-group.png';
import HubspotContactForm from "./HubspotContactForm";
import { trilegalDetails,trilegalProgramDetails } from "../utils/constant";


export const BaseContainer = (props) => {
    return (
        <Container maxWidth='lg'>
            <Box style={{ marginTop:'10px'}}>
                {props.children}
            </Box>
        </Container>
    );
};

const textStyle = {
    color:'#0D3C42',
    fontWeight:'100',
    fontStyle: 'normal',
    fontFamily:'Cinzel',
    lineHeight:'101%'
}


const renderProgramDetails = () => {
    return (
        <Typography style={{fontSize:'15px',marginTop:'10px',whiteSpace: "pre-wrap"}}>
            <b>Duration:</b> {trilegalProgramDetails.duration}<br/><br/><b>Dates for workshop:</b> {trilegalProgramDetails.dates}<br/><br/><b>Time commitment:</b> {trilegalProgramDetails.timeCommitment}<br/><br/>
            <b>Mode of communication:</b> {trilegalProgramDetails.mode}<br/><br/><b>Skills you will learn:</b>
            <br/>{trilegalProgramDetails.skills}<br/><br/><b>Syllabus:</b><br/>{trilegalProgramDetails.syllabus}
        </Typography>
    )
}
const renderAboutTrilegal = () => {
    return (
        <Typography style={{fontSize:'15px',marginTop:'10px',whiteSpace: "pre-wrap"}}>
            {trilegalDetails}
        </Typography>
    )
}

const renderTable = ()=>{
    const data = [
        {description:'Orientation Session (for students and parents)',date :' May 15, 2023',week:'6-7 PM'},
        {description:'Introductory Session: Who makes laws in India, what are the basic authorities that one must be aware of and what do they do?',date:' May 16, 2023',week:'6-7 PM'},
        {description:'Introductory Session: What are the different applications of law?',date:'May 19, 2023',week:'6-7 PM'},
        {description:'Deep-dive: How does law deal with technology in an ever-evolving world?',date:'May 22, 2023',week:'6-7 PM'},
        {description:'Deep-dive: Brief of laws involved in running a business',date:'May 24, 2023',week:'6-7 PM'},
        {description:'Deep-dive: How is law addressing sustainability and climate exigencies?',date:'May 31, 2023',week:'5-6 PM'},

        {description:'Deep-dive: Brief of laws around women, children, and disadvantaged communities',date:' June 2, 2023',week:'6-7 PM'},
    ]
    return (
        <table style={{marginTop:'20px'}}>
        <tr >      
            <th style={{width:'60%',textAlign:'center',backgroundColor:'#0D3C42',color:'#fff',height:'40px'}}>Description</th>
          <th style={{width:'40%',textAlign:'center',backgroundColor:'#0D3C42',color:'#fff',height:'40px'}}>Dates and Timings</th>
        </tr>
        {
            data.map(item=>(
                <tr style={{height:'40px'}}>
                  
                    <td style={{padding:'10px',fontWeight:'400'}}>{item.description}</td>
                    <td style={{padding:'10px',textAlign:'center',whiteSpace: "pre-wrap"}}>
                        <Box>
                            <Typography style={{fontWeight:'400',fontSize:'12px'}}>{item.date}</Typography>
                            <Typography style={{fontWeight:'550',fontSize:'12px'}}>{item.week}</Typography>
                        </Box></td>
                </tr>
            ))
        }
      </table>
      )
}

const renderTextFieldGain = () => {
    return(
        <Box>
            <Typography style={{...textStyle,fontSize:'20px',fontWeight:'600',fontFamily:'League Spartan!important',borderBottom:'1px solid #0D3C42',width:'200px',height:'40px'}}> What you’ll gain:</Typography>
            <br/>
            <Typography style={{fontSize:'15px'}}>• An official certificate issued by Athena Education and Trilegal. 
            <br/>• A unique opportunity to assess if you are suited to a career as a corporate lawyer. 
            <br/>• Ability to develop well rounded applications to foreign universities that demonstrate your knowledge of the law.
            <br/>• 10 deserving students will also get the once-in-a-lifetime chance to work with Trilegal lawyers! 
            <br/>*  <span style={{fontWeight:'600'}}>     Please Note:</span>This will only be open to Athena students in 11th and 12th grades.
            <br/>• These selected students will work out of Trilegal’s offices in Delhi, Mumbai, and Bengaluru for 3 weeks. 
            <br/>• A few outstanding selected students will receive glowing letters of recommendations for their work and contribution. </Typography>
            
        </Box>
    )
}



const Trilegal = () => {
    const [open,setOpen] =useState();
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Fragment>
        <BaseContainer>
            <Box style={{backgroundImage:`url(${lawBackground})`,backgroundSize: 'cover',padding:'2%',width:'120%',marginLeft:'-10%'}}>
            <Grid container style={{rowGap:'30px',paddingInline:'6%'}}>
            {/* <img src={intershipLandingBanner} style={{position:'absolute',zIndex:'-1',top:'17%',left:'0'}}/> */}
                <Grid xs={12}>
                    <span 
                    style={{...textStyle}} className="trilegal-heading" >THE ATHENA LAW IMMERSION PROGRAM</span> 
                </Grid>
                <Grid xs={12}>
                    <Typography style={{...textStyle,fontSize:'20px'}}><span style={{fontWeight:'600'}}>Athena Education</span> in collaboration with <span style={{fontWeight:'600'}}>Trilegal</span></Typography>
                </Grid>
                {/* <Grid xs={12}>
                    <img src={PresentByGroup} style={{width:'350px'}}/>
                </Grid> */}
                <Grid xs={12}>
                    <span style={{...textStyle}} className="trilegal-description">
                    Experience how India’s best legal minds work through a <span style={{fontWeight:'600'}}>3-week workshop</span>.
                    </span>
                </Grid>
                <Grid xs={12}>
                    <Typography style={{...textStyle,fontSize:'20px',backgroundColor:'#FFF',border:'1px solid #0D3C42',padding:'20px',borderRadius:'20px'}}>
                    <span style={{fontWeight:'600'}}>Key Advantages:</span> <br/><br/>• Understand how legislation influences business . 
                    <br/><br />• An official certificate issued by Athena Education and Trilegal. <br/><br/> <span style={{fontWeight:'600'}}>• 10 deserving students</span> will also get the once-in-a-lifetime chance to work with Trilegal’s lawyers.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                <Button style={{backgroundColor:'#0D3C42',borderRadius:'20px',color:'#fff',marginTop:'10px',width:'40%',height:'40px',textTransform:'none',fontSize:'14px'}}
                    onClick={()=>{
                        setOpen(true);
                    }}
                    >Sign up today</Button>
                </Grid>

            </Grid>
            </Box>
            <Grid xs={12} item>
                <Typography style={{...textStyle,fontSize:'20px',fontWeight:'600',marginTop:'20px',height:'40px',width:'200px',fontFamily:'League Spartan!important',borderBottom:'1px solid #0D3C42'}}>About Trilegal</Typography>
            </Grid>
            <Grid xs={12} item>
                {renderAboutTrilegal()}
            </Grid>
            <Grid xs={12} item>
                <Typography style={{...textStyle,fontSize:'20px',fontWeight:'600',marginTop:'20px',height:'40px',width:'200px',fontFamily:'League Spartan!important',borderBottom:'1px solid #0D3C42'}}>Program Details</Typography>
            </Grid>
            <Grid xs={12}>
            {renderProgramDetails()}
           
            <br/>
            {renderTable()}
            <br/>
            {renderTextFieldGain()}
            <br/>
            <Button style={{backgroundColor:'#0D3C42',borderRadius:'20px',color:'#fff',marginTop:'10px',width:'40%',height:'40px',textTransform:'none',fontSize:'14px'}}
                    onClick={()=>{
                        setOpen(true);
                    }}
                    >Sign up today</Button>
            </Grid>
            <Grid xs={12}>
            <Box  style={{height:'100px'}}/>
            </Grid>
            <Dialog onClose={handleClose} open={open}>
                
                <Box style={{padding:'15px',zIndex:'999'}}>
                <Typography style={{fontSize:'20px',fontWeight:'400',textAlign:'center',fontFamily:'Cinzel',color:'#0D3C42'}}>Sign up Information</Typography>
                <br/>
                <HubspotContactForm formId = 'c0d845a2-2e3d-499e-a5ce-409343b13516'/>
                </Box>
                
            </Dialog>
            
        </BaseContainer>
        </Fragment>
    )
}

export default Trilegal